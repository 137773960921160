import React from "react";
import Modal from "pages/manifest/modal";

const AssignmentVideoModal = ({ onClickClose, cardId, videoId }) => {
  return (
    <Modal isOpen onClickClose={onClickClose} id={"assignmentVideoModal"}>
      {/* <iframe src={`//latakoo.com/pilot2/?show=${videoId}&expand=1&assignment_id=${cardId}`} title="Pilot" ></iframe> */}
      <iframe src={`//pilot.latakoo.com/single/${videoId}`} title="Pilot"></iframe>
    </Modal>
  );
};

export default AssignmentVideoModal;
