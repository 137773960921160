import React from "react";

import { latakooBirdNormal, latakooAudioIcon } from "../../../images/latakooImages.json";
import AssetTypes from "constants/asset-types";

const CardHeaderThumbnail = ({ card, gcFlag, clickOpenArea }) => {
  let assetTypes = card.assignment_video_types;

  let hasAudio = false;
  let hasFolder = false;
  let hasOther = false;
  for (const key in assetTypes) {
    for (const asset of assetTypes[key]) {
      if (asset.asset_type === AssetTypes.AUDIO) {
        hasAudio = true;
      } else if (asset.asset_type === AssetTypes.FOLDER) {
        hasFolder = true;
      } else if (asset.asset_type === AssetTypes.OTHER) {
        hasOther = true;
      }
    }
  }

  let icon = <div className="thumbnail-icon-other"></div>;
  if (card.first_thumbnail) {
    icon = <img className="latakoo-image" src={card.first_thumbnail} alt={card.title} onError={onErrorLoadingImg} />;
  } else if (hasFolder) {
    icon = <div className="thumbnail-icon-folder"></div>;
  } else if (hasAudio) {
    icon = <img src={latakooAudioIcon} alt={card.title} onError={onErrorLoadingImg} />;
  } else if (hasOther) {
    icon = <div className="thumbnail-icon-other"></div>;
  } else {
    icon = <img className="latakoo-image" src={latakooBirdNormal} alt="latakoo Bird" />;
  }

  function onErrorLoadingImg(e) {
    e.target.src = latakooBirdNormal;
    e.target.className = "latakoo-image";
  }

  return (
    <div
      className={`card-header-video-thumb ${card.first_thumbnail ? "hasThumbnail" : "noThumbnail"}`}
      title={gcFlag ? `Unassigned Assets` : `Assigned Asset`}
      onClick={() => clickOpenArea(card.id)}
    >
      <div className={`video-thumb_asset-type`}>{icon}</div>
    </div>
  );
};

export default CardHeaderThumbnail;
