import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import { useUser } from "contexts/userContext";
import { useFeed } from "contexts/feedContext";

const FeedTags = () => {
  const { user } = useUser();
  const { feedSelectedTag, selectFeedTag } = useFeed();

  if (!user.tags) {
    return <div>Loading...</div>;
  }

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: Math.min(8, user.tags.length),
    slidesToScroll: 1,
    arrows: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: Math.min(6, user.tags.length),
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: Math.min(5, user.tags.length),
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Math.min(3, user.tags.length),
        },
      },
    ],
  };

  const renderTag = (tag) => {
    const { pathname, search } = window.location;

    const isSelected = feedSelectedTag === tag;
    let url = pathname;
    if (!isSelected) {
      const queryParameters = new URLSearchParams(search);
      queryParameters.set("tag", tag);
      url += `?${queryParameters.toString()}`;
    }

    const onTagSelected = () => {
      selectFeedTag(tag);
    };

    return (
      <Link className={isSelected ? "selected" : ""} key={tag} onClick={onTagSelected} to={url}>
        {tag}
      </Link>
    );
  };

  const renderTags = (tags) => tags.map(renderTag);

  return (
    <div className="feed-tags-container">
      {user.tags.length > 0 && (
        <div className="feed-tags">
          <Slider {...sliderSettings}>{renderTags(user.tags)}</Slider>
          {/* {renderTags(user.tags)} */}
        </div>
      )}
    </div>
  );
};

export default FeedTags;
