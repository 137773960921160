import React, { useRef, useState, useEffect } from "react";

const DragAndDrop = ({ handleDrop, children }) => {
  const [dragging, setDragging] = useState(false);

  let dragCounter = 0;
  const dropRef = useRef(null);

  function handleDrag(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  function handleDragIn(e) {
    e.preventDefault();
    e.stopPropagation();
    dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  }

  function handleDragOut(e) {
    e.preventDefault();
    e.stopPropagation();
    dragCounter--;
    if (dragCounter === 0) {
      setDragging(false);
    }
  }

  function handleDropf(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleDrop(e.dataTransfer.files);
      e.dataTransfer.clearData();
      dragCounter = 0;
    }
  }

  useEffect(() => {
    dropRef.current.addEventListener("dragenter", (e) => handleDragIn(e));
    dropRef.current.addEventListener("dragleave", (e) => handleDragOut(e));
    dropRef.current.addEventListener("dragover", (e) => handleDrag(e));
    dropRef.current.addEventListener("drop", (e) => handleDropf(e));

    return () => {
      dropRef.current.removeEventListener("dragenter", (e) => handleDragIn(e));
      dropRef.current.removeEventListener("dragleave", (e) => handleDragOut(e));
      dropRef.current.removeEventListener("dragover", (e) => handleDrag(e));
      dropRef.current.removeEventListener("drop", (e) => handleDropf(e));
    };
  }, []);

  return (
    <div className="drag0" ref={dropRef}>
      {dragging && (
        <div className="drag1">
          <div className="drag2">
            <div>drop files here</div>
          </div>
        </div>
      )}
      {children}
    </div>
  );
};
export default DragAndDrop;
