import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Multiselect } from "multiselect-react-dropdown";
import { useUser } from "contexts/userContext";

const AutocompleteAssignedTo = ({ className, index, onChangeAlt, value, id }) => {
  const { user, tokenAccessApi } = useUser();

  let preSelectedValues = [];
  value.map((val) => {
    let v = {
      id: val.id.toString(),
      text: val.name,
    };
    preSelectedValues.push(v);
  });

  const inputEl = useRef([]);
  const selectRef = useRef("");
  const [assTo, setAssTo] = useState(preSelectedValues);

  function onSelect(selectedList, selectedItem) {
    onChangeAlt(index, [...assTo, selectedItem], id);
    setAssTo([...assTo, selectedItem]);
  }

  function onRemove(selectedList, removedItem) {
    function checkAT(at) {
      return at !== removedItem;
    }
    onChangeAlt(index, assTo.filter(checkAT), id);
    setAssTo(assTo.filter(checkAT));
  }

  const [search, setSearch] = useState("");
  const [options, setOptions] = useState(preSelectedValues.length > 0 ? preSelectedValues : []);

  const searchAssignee = React.useCallback(async () => {
    const url = `${process.env.REACT_APP_USERS_API_LC_BASE_URL}/organization/${user.organization_id}${
      search ? `?criteria=${search}` : ""
    }`;

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${tokenAccessApi}`,
        },
      });
      setOptions(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [search, user, tokenAccessApi]);

  useEffect(() => {
    searchAssignee();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  // at least for me, options is almost 20k items long,
  // which was causing this component to take over 100ms to render -- on every single input keystroke
  // and you don't even need the options unless the user is searching
  // so we'll only render the options if the input is focused and the user has typed something
  // this is FAR from a perfect solution -- you'll see that if you type something, then ctrl+a then backspace, or you type something then click out of the input and type elsewhere
  // but it's good enough for now, we'll rewrite all this soon enough anyway :)
  const showOptions =
    !!selectRef.current?.state?.inputValue && selectRef.current?.searchBox?.current === document.activeElement;

  return (
    <div className={`assigned-to-dropdown ${className} form-row`}>
      <div className="label-title">Assigned to</div>
      <input type="hidden" ref={inputEl} id="assignedTo" name="Assigned To" index={index} value={assTo} />
      <Multiselect
        placeholder=""
        options={showOptions ? options : []}
        emptyRecordMsg={showOptions ? "No results found" : "Start typing to search"}
        displayValue="text"
        closeIcon="cancel"
        closeOnSelect
        onSearch={(val) => setSearch(val)}
        selectedValues={assTo} // Preselected value to persist in dropdown
        onSelect={onSelect}
        onRemove={onRemove}
        ref={selectRef}
      />
    </div>
  );
};

export default AutocompleteAssignedTo;
