import React from "react";

const Select = ({ options, className, name, id, onChange, index, value }) => {
  const defaultValue = options.length > 1 ? "Select an option" : options[0];

  return (
    <div className={`form-row select-control ${className}`}>
      <div className="label-title">{name}</div>
      <select
        className={`browser-default custom-select`}
        name={name}
        id={id}
        onChange={onChange}
        index={index}
        defaultValue={value ? undefined : defaultValue}
        value={value}
      >
        {options.map((option, i) => {
          let value = option.toString();
          let label = option.toString();
          if (typeof option === "object" && option !== null && !!option.value) {
            value = option.value;
            label = option.label || option.value.toString();
          }
          return (
            <option key={i} value={value}>
              {label}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default Select;
