import React, { createContext, useCallback, useContext, useState } from "react";
import axios from "axios";
import { useLocalState } from "components/utils/useLocalState";
import { useUser } from "contexts/userContext";

export const FeedContext = createContext();

export const FeedLayout = {
  GRID: "grid",
  LIST: "list",
};

export const FeedSourceType = {
  HOME_ORGANIZATION: "home-organization",
  ORGANIZATION: "organization",
  SINGLE_TOPIC: "topic",
  TOP_ORGANIZATION: "top-organization",
  USER: "user",
  CONNECT_ORGANIZATION: "connect-organization",
  CONNECT_SINGLE_TOPIC: "connect-single-topic",
};

export function FeedContextProvider({ children }) {
  const { orgsTree, tokenAccessApi, user } = useUser();
  const [feedGeneralContent, setFeedGeneralContent] = useLocalState("generalContent", true);

  const getFeedConfig = () => {
    const config = {
      page: 1,
      source: undefined,
      subject: undefined,
    };

    const { pathname } = window.location;

    if (pathname === "/assignment/user") {
      config.source = FeedSourceType.USER;
    } else if (pathname.startsWith("/topic")) {
      config.source = FeedSourceType.SINGLE_TOPIC;
      const [subject] = pathname.split("topic/")[1].split("/");
      config.subject = subject;
    } else if (pathname.startsWith("/connect-topic")) {
      config.source = FeedSourceType.CONNECT_SINGLE_TOPIC;
      const [subject] = pathname.split("connect-topic/")[1].split("/");
      config.subject = subject;
    } else {
      if (pathname === "/") {
        // config.source = FeedSourceType.HOME_ORGANIZATION;
        config.source = FeedSourceType.CONNECT_ORGANIZATION;
        config.subject = orgsTree.public_trees.public_id_path;
      } else if (pathname.startsWith("/assignment/organization")) {
        if (pathname.endsWith("organization")) {
          config.source = FeedSourceType.HOME_ORGANIZATION;
        } else {
          config.source = FeedSourceType.ORGANIZATION;
          config.subject = pathname.split("/").pop();
        }
      } else if (pathname.startsWith("/assignment/connect-organization")) {
        config.source = FeedSourceType.CONNECT_ORGANIZATION;
        config.subject = pathname.split("/").pop();
      } else if (pathname === "/assignment/top-organization") {
        config.source = FeedSourceType.TOP_ORGANIZATION;
      }
    }

    return config;
  };

  const getSelectedTag = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    return queryParameters.get("tag") ?? undefined;
  };

  const [feedConfig, setFeedConfig] = useState(() => getFeedConfig());
  const [feedLayout, setFeedLayout] = useLocalState("feedLayout", FeedLayout.GRID);
  const [feedLoading, setFeedLoading] = useState(true);

  const initialFeedData = {
    data: [],
    pagination: undefined,
    error: null,
  };
  const [feedData, setFeedData] = useState(initialFeedData);
  const [feedSelectedTag, setFeedSelectedTag] = useState(() => getSelectedTag());

  const selectFeedTag = (feedTag) => {
    if (feedSelectedTag === feedTag) {
      setFeedSelectedTag(undefined);
    } else {
      setFeedSelectedTag(feedTag);
    }
    resetFeedData();
  };

  const getFeedApiUrl = useCallback(() => {
    let apiUrl;

    // if (feedConfig.source === FeedSourceType.SINGLE_TOPIC) {
    //   apiUrl = process.env.REACT_APP_API_BASE_URL;
    //   apiUrl += `/topic/${feedConfig.subject}`;

    //   return apiUrl;
    // }

    if (
      feedConfig.source === FeedSourceType.SINGLE_TOPIC ||
      feedConfig.source === FeedSourceType.CONNECT_SINGLE_TOPIC
    ) {
      apiUrl = process.env.REACT_APP_TOPICS_API_LC_BASE_URL;
      apiUrl += `/${feedConfig.subject}`;

      return apiUrl;
    }

    /*
    if (feedLayout === FeedLayout.LIST) {
      apiUrl = `${process.env.REACT_APP_API_BASE_URL}/assignment-list`;

      if (feedConfig.source === FeedSourceType.USER) {
        apiUrl += '/user';
      } else {
        apiUrl += feedConfig.source === FeedSourceType.TOP_ORGANIZATION ? '/top-organization' : '/organization';
  
        if (feedConfig.source === FeedSourceType.ORGANIZATION || feedConfig.source === FeedSourceType.CONNECT_ORGANIZATION) {
          apiUrl += `/${feedConfig.subject}`;
        }
      }

      return apiUrl;
    }*/

    apiUrl = process.env.REACT_APP_TOPICS_API_LC_BASE_URL;

    return apiUrl;
  }, [feedConfig.source, feedConfig.subject, feedLayout]);

  const resetFeedData = () => {
    setFeedData(initialFeedData);
  };
  const getFeedApiQueryParams = useCallback(() => {
    const queryParameters = new URLSearchParams(window.location.search);

    if (
      feedConfig.source === FeedSourceType.SINGLE_TOPIC ||
      feedConfig.source === FeedSourceType.CONNECT_SINGLE_TOPIC
    ) {
      return queryParameters;
    }

    if (feedData.pagination?.hasMore) {
      queryParameters.set("cursor", feedData.pagination.cursor);
    }
    /*
    if (feedLayout === FeedLayout.LIST) {
      queryParameters.set('ownerid', user.id);
      queryParameters.set('_limit', feedLayout === FeedLayout.GRID ? 5 : 10);
      queryParameters.set('_page', feedConfig.page);
      queryParameters.set('include-gc', feedGeneralContent);
      queryParameters.set('random', Math.floor(Math.random() * 1E16));
    
      if (feedSelectedTag) {
        queryParameters.set('tag', feedSelectedTag);
      }

      if (feedConfig.page > 1) {
        const lastAKey = feedData.data[feedData.data.length - 1].a_key;
        queryParameters.set('a_key', lastAKey);
      }

      return queryParameters;
    }*/

    if (feedConfig.source === FeedSourceType.USER) {
      queryParameters.set("owner", "self");
      queryParameters.set("orgIds", user.all_org_ids);
      queryParameters.set("connectOrgIds", orgsTree.public_trees.public_id_path);
    } else if (feedConfig.source === FeedSourceType.TOP_ORGANIZATION) {
      queryParameters.set("orgIds", user.all_org_ids);
    } else if (feedConfig.source === FeedSourceType.HOME_ORGANIZATION) {
      queryParameters.set("orgIds", `${user.organization_id}`);
    } else if (feedConfig.source === FeedSourceType.CONNECT_ORGANIZATION) {
      queryParameters.set("connectOrgIds", feedConfig.subject);
    } else {
      console.log("falling here", feedConfig.source);
      queryParameters.set("orgIds", feedConfig.subject);
    }

    if (feedSelectedTag) {
      queryParameters.set("tags", feedSelectedTag);
    }

    queryParameters.set("contentMode", feedGeneralContent ? "all-content" : "assignments-only");
    queryParameters.set("limit", 20);
    // TODO: add proper support for PaginationMode
    queryParameters.set("paginationMode", "older");

    return queryParameters;
  }, [feedConfig, feedGeneralContent, feedLayout, feedSelectedTag, feedData, orgsTree, user]);

  const feedAPI = useCallback(async () => {
    setFeedLoading(true);

    const apiUrl = getFeedApiUrl();
    const apiQueryParams = getFeedApiQueryParams();

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${tokenAccessApi}`,
        },
        params: apiQueryParams,
      });
      console.log("feedAPI response: ", response);

      setFeedData({
        data:
          feedConfig.source === FeedSourceType.SINGLE_TOPIC || feedConfig.source === FeedSourceType.CONNECT_SINGLE_TOPIC
            ? response.data
            : [...feedData.data, ...(!response.data ? [] : response.data.items)],
        error: null,
        pagination:
          feedConfig.source === FeedSourceType.SINGLE_TOPIC || feedConfig.source === FeedSourceType.CONNECT_SINGLE_TOPIC
            ? undefined
            : response.data.pagination,
      });
    } catch (error) {
      setFeedData({
        data: [],
        error,
      });
    } finally {
      setFeedLoading(false);
    }
  }, [feedConfig.source, getFeedApiUrl, getFeedApiQueryParams, feedData, setFeedData, tokenAccessApi]);

  const contextValue = {
    feedAPI,
    feedConfig,
    feedGeneralContent,
    feedLayout,
    feedLoading,
    feedSelectedTag,
    getFeedApiUrl,
    feedData,
    setFeedData,
    resetFeedData,
    selectFeedTag,
    setFeedConfig,
    setFeedGeneralContent,
    setFeedLayout,
    setFeedLoading,
  };

  return <FeedContext.Provider value={contextValue}>{children}</FeedContext.Provider>;
}

export const useFeed = () => useContext(FeedContext);
