import React, { useEffect } from "react";
import InfiniteScroll from "components/utils/infiniteScroll";
import FeedGrid from "components/feed/feedGrid/feedGrid";

import { FeedLayout, FeedSourceType, useFeed } from "../../contexts/feedContext";
import LoadingIndicator from "components/loadingIndicator";
import { useUser } from "contexts/userContext";

const FeedView = () => {
  const { orgsTree } = useUser();
  const { feedAPI, feedConfig, feedData, feedGeneralContent, feedLayout, feedLoading, feedSelectedTag, setFeedConfig } =
    useFeed();

  let isGridLayout = feedLayout === FeedLayout.GRID;
  let isListLayout = feedLayout === FeedLayout.LIST;

  const singleTopicRequest =
    feedConfig.source === FeedSourceType.SINGLE_TOPIC || feedConfig.source === FeedSourceType.CONNECT_SINGLE_TOPIC;

  if (feedConfig.source === FeedSourceType.SINGLE_TOPIC || feedConfig.source === FeedSourceType.CONNECT_SINGLE_TOPIC) {
    isGridLayout = true;
    isListLayout = false;
  }

  function fetchMore() {
    // console.log('fetchMore feedAPI');

    setFeedConfig({
      ...feedConfig,
      page: feedConfig.page + 1,
    });
  }

  useEffect(() => {
    feedAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedConfig, feedGeneralContent, feedLayout, feedSelectedTag]);

  // let searchLogic = ''

  // function closeCountAlert() {
  // 	console.log('close')
  // }

  const emptyData = !feedData.data || feedData.data.length === 0;

  const isConnect =
    feedConfig.source === FeedSourceType.CONNECT_SINGLE_TOPIC ||
    feedConfig.source === FeedSourceType.CONNECT_ORGANIZATION;
  const hasManifest = orgsTree.userHasManifest;
  const showPromotionalVideo = !isConnect && !hasManifest;

  return (
    <div className="feed-list">
      {showPromotionalVideo && (
        <div className="promotional_video-container">
          <iframe
            id="embedId"
            type="text/html"
            width="100%"
            height="568px"
            src="https://api2.latakoo.com/media/stream/11311559?mode=video&public_token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2NjA2MTMyMTEsImlkIjozMzU5MSwibWVkaWFfaWQiOiIxMTMxMTU1OSIsInBhdGgiOiJcL21lZGlhXC9zdHJlYW1cLzExMzExNTU5IiwiaHR0cE1ldGhvZCI6IkdFVCJ9.FzOD_pIhUayt56ekApUV6y21BLVon2U_lwFOTJYB6oc&userid=33591"
            frameBorder="0"
            allowFullScreen={true}
            data-type="video"
            scrolling="no"
          ></iframe>
        </div>
      )}
      {!showPromotionalVideo && (
        <>
          {!feedLoading && feedData.error && (
            <div className="error-message">There has been an error, please try again</div>
          )}

          {!feedLoading && emptyData && (
            <div className="empty-results">
              <h3>No Recent Activity</h3>
              <p>
                Would you like to create new <a href="/addtopic">Topics and Assignments</a>?
              </p>
            </div>
          )}

          {!emptyData && <FeedGrid cards={feedData.data} singleTopic={singleTopicRequest} />}

          {feedLoading && <LoadingIndicator />}

          {!emptyData &&
            !feedLoading &&
            feedData.pagination?.hasMore &&
            feedConfig.source !== FeedSourceType.SINGLE_TOPIC &&
            feedConfig.source !== FeedSourceType.CONNECT_SINGLE_TOPIC && <InfiniteScroll fetchMore={fetchMore} />}

          <div className="after-infinite-scroll"></div>
        </>
      )}
    </div>
  );
};

export default FeedView;
