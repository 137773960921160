import React, { useEffect, useState } from "react";
import useApi from "components/utils/useApi";
import Assignments from "components/feed/feedGrid/assignments";
import InfiniteScroll from "components/utils/infiniteScroll";
import { useUser } from "contexts/userContext";
import LoadingIndicator from "components/loadingIndicator";

const baseParams = {
  _limit: 5,
};

const AssignmentsList = ({ topic, isOwner, isGeneralContent }) => {
  const { user, tokenAccessApi } = useUser();
  const [page, setPage] = useState(1);
  const cardId = topic.id;

  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set("ownerid", user.id);

  let akey = "";
  const [load, loadInfo] = useApi({
    // url: `/assignment-children/${topic.id}?${searchParams.toString()}`,
    baseURL: process.env.REACT_APP_TOPICS_API_LC_BASE_URL,
    url: `/${topic.id}/assignments`,
    headers: {
      Authorization: `Bearer ${tokenAccessApi}`,
    },
    method: "get",
  });

  useEffect(() => {
    load({
      params: {
        ...baseParams,
        cardId,
        _page: 1,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function fetchMore() {
    const newPage = page + 1;
    akey = loadInfo.data[loadInfo.data.length - 1].a_key;
    load({
      isFetchMore: true,
      params: {
        ...baseParams,
        _page: newPage,
        a_key: akey,
      },
      updateRequestInfo: (newRequestInfo, prevRequestInfo) => ({
        ...newRequestInfo,
        data: [...prevRequestInfo.data, ...newRequestInfo.data],
      }),
    });

    setPage(newPage);
  }

  return (
    <div>
      {loadInfo.loading && <LoadingIndicator />}
      {!loadInfo.loading && loadInfo.error && (
        <div className="error-message">
          There has been an error, please try again or contact{" "}
          <a href="mailto:support@latakoo.com" target="_blank">
            latakoo customer support
          </a>
        </div>
      )}
      {loadInfo.data && !loadInfo.loading && !loadInfo.error && (
        <Assignments
          // loading={loadInfo.loading}
          // error={loadInfo.error}
          assignments={loadInfo.data}
          topic={topic}
          isOwner={isOwner}
          isGeneralContent={isGeneralContent}
        />
      )}

      {loadInfo.data && !loadInfo.loading && loadInfo.more && !loadInfo.error && (
        <InfiniteScroll fetchMore={fetchMore} />
      )}
    </div>
  );
};

export default AssignmentsList;
