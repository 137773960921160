import React, { useEffect, useState } from "react";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import { MDBIcon } from "mdbreact";

const DateTimePicker = ({ index, onChangeAlt, className, label, value, id }) => {
  const initVal = value === undefined ? "" : Date.parse(new Date(value));

  const [startDate, setStartDate] = useState(initVal);

  useEffect(() => {
    if (startDate !== "") {
      onChangeAlt(index, new Date(startDate).toLocaleString(), id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  return (
    <div className={className}>
      <span>
        <MDBIcon icon="calendar-alt" />
      </span>
      <div className="label-title">{label}</div>
      <Datetime onChange={(date) => setStartDate(date)} initialValue={startDate} closeOnSelect={true} />
    </div>
  );
};

export default DateTimePicker;
