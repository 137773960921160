import React, { createContext, useEffect, useState } from "react";

const WebviewContext = createContext();

export const MobileWebviewContextProvider = ({ children }) => {
  const [isMobileWebview, setIsMobileWebview] = useState(false);
  useEffect(() => {
    // we store it in localStorage because if you somehow manage to reload the page in the webview, we still want to know that it's a webview
    // (I managed to reload the page somehow in testing, I forget how, and it kicked me back to the normal web UI, which looks bad in the app)
    if (window.location.search.includes("mobileWebview=1") || localStorage.isMobileWebview) {
      localStorage.isMobileWebview = true;
      setIsMobileWebview(true);
    }
  }, []);
  return <WebviewContext.Provider value={{ isMobileWebview }}>{children}</WebviewContext.Provider>;
};

export const useMobileWebview = () => React.useContext(WebviewContext);
