import React from "react";
import axios from "axios";
import Modal from "pages/manifest/modal";
import $ from "jquery";

const DeleteVideoModal = ({ onClickClose, videoId, assignmentId, userId, orgId }) => {
  function deleteVideo(videoId) {
    axios
      .post(
        `${process.env.REACT_APP_GATEWAY_API_BASE_URL}?videoid=${videoId}&userid=${userId}&mode=12&assignmentid=${assignmentId}&org_id=${orgId}`,
      )
      .then(function (response) {
        if (response === "false") {
          $("#modalVideoLabel_" + videoId).html("There was an error trying to delete");
          return false;
        } else {
          $("#modalVideoLabel_" + videoId).html("Video deleted, please wait while we archive our records.");
          window.location.reload();
          return true;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <Modal isOpen onClickClose={onClickClose} id={`deleteVideoModal_${videoId}`}>
      <div className="modal-title delete-modal-title" id={`modalVideoLabel_${videoId}`}>
        Are you sure you want to delete this Video?
      </div>
      <input type="hidden" id={`assignment_${videoId}`} name={`assignment_${videoId}`} value={assignmentId}></input>
      <div className="form-row form-row-buttons">
        <button type="button" className="btn-secondary" onClick={onClickClose}>
          Close
        </button>
        <input
          id={`tag-form-submit_${videoId}`}
          type="submit"
          className="btn-primary delete-modal_delete-btn"
          value="Delete"
          onClick={() => deleteVideo(videoId)}
        />
      </div>
    </Modal>
  );
};

export default DeleteVideoModal;
