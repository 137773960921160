import React, { createContext, useContext, useEffect } from "react";
import $ from "jquery";

import { useLocalState } from "components/utils/useLocalState";
import { useUser } from "./userContext";

export const SettingsContext = createContext();

export function SettingsContextProvider({ children }) {
  const { sidebarWidth } = useUser();
  const [fontSize, setFontSize] = useLocalState("fontSize", 16);
  const [feedContainerWidth, setFeedContainerWidth] = useLocalState("feedContainerWidth", null);

  const increaseFontSize = () => {
    setFontSize(fontSize + 1);
  };

  const decreaseFontSize = () => {
    if (fontSize > 10) {
      setFontSize(fontSize - 1);
    }
  };

  useEffect(() => {
    $("html").css("font-size", `${fontSize}px`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fontSize]);

  useEffect(() => {
    function handleWindowResize(event) {
      setFeedContainerWidth(window.innerWidth - sidebarWidth);
    }

    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [sidebarWidth]);

  return (
    <SettingsContext.Provider
      value={{
        fontSize,
        increaseFontSize,
        decreaseFontSize,
        feedContainerWidth,
        setFeedContainerWidth,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export const useSettings = () => useContext(SettingsContext);
