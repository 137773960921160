export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === null) return "";
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
};

export const formatSeconds = (length) => {
  if (length === null) return "";
  if (length === 0) return "";
  let totalSeconds = length;
  let hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  let minutes = Math.floor(totalSeconds / 60);
  let seconds = totalSeconds % 60;

  let result = "";
  if (hours === 0) {
    if (minutes === 0) {
      result = seconds + "s";
    } else {
      result = minutes + "m " + seconds + "s";
    }
  } else {
    result = hours + "h " + minutes + "m " + seconds + "s";
  }

  return result;
};

export const checkForComma = (size, length) => {
  let hasSize = size !== null && size !== 0 ? true : false;
  let hasLength = length !== null && length !== 0 ? true : false;

  return hasSize && hasLength ? ", " : "";
};

export const formatDateTime = (datetime) => {
  const now = new Date(datetime * 1000);
  const month = now.getMonth() + 1;
  const day = now.getDate();
  const year = now.getFullYear();
  const hour = now.getHours() > 12 ? now.getHours() - 12 : now.getHours();
  const minutes = "0" + now.getMinutes();
  return `${month}/${day}/${year}, ${hour === 0 ? "12" : hour}:${minutes.substr(-2)}${
    now.getHours() > 11 ? "PM" : "AM"
  }`;
};

export const formatDateTimeSeconds = (datetime) => {
  const now = new Date(datetime * 1000);
  const month = now.getMonth() + 1;
  const day = now.getDate();
  const year = now.getFullYear();
  const hour = now.getHours() > 12 ? now.getHours() - 12 : now.getHours();
  const minutes = "0" + now.getMinutes();
  const seconds = "0" + now.getSeconds();
  return `${month}/${day}/${year}, ${hour === 0 ? "12" : hour}:${minutes.substr(-2)}:${seconds.substr(-2)} ${
    now.getHours() > 11 ? "PM" : "AM"
  }`;
};
