import React from "react";
import axios from "axios";
// import { Link } from 'react-router-dom';
import Modal from "pages/manifest/modal";
import $ from "jquery";

const DeleteTopicModal = ({ onClickClose, id, userId, orgId }) => {
  function deleteForm(id, userId, orgId) {
    axios
      .post(`${process.env.REACT_APP_GATEWAY_API_BASE_URL}?userid=${userId}&mode=11&assignmentid=${id}&org_id=${orgId}`)
      .then(function (response) {
        console.log(response.data);
        if (response === "false") {
          $("#modalLabel_" + id).html("There was an error trying to delete");
          return false;
        } else {
          $("#modalLabel_" + id).html("Assignment deleted, please wait while we render the page.");
          window.location.reload();
          return true;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <Modal isOpen onClickClose={onClickClose} id={`deleteModal_${id}`}>
      <div className="modal-title delete-modal-title" id={`modalLabel_${id}`}>
        Are you sure you want to delete this Topic?
      </div>
      <input type="hidden" id={`assignment_${id}`} name={`assignment_${id}`} value={id}></input>

      <div className="form-row form-row-buttons">
        <button type="button" className="btn-secondary" onClick={onClickClose}>
          Close
        </button>
        <input
          id={`tag-form-submit_${id}`}
          type="submit"
          className="btn-primary delete-modal_delete-btn"
          value="Delete"
          onClick={() => deleteForm(id, userId, orgId)}
        />
      </div>
    </Modal>
  );
};

export default DeleteTopicModal;
