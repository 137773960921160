import React from "react";
import { useUser } from "contexts/userContext";

const VideoIframe = ({ video, mode }) => {
  const { user, tokenAccessApi } = useUser();

  const isDev = /^(local|dev)/.test(window.location.hostname);

  // very quick and dirty image display. this should be temporary.
  if (mode === "image") {
    let largeThumbnail = video.thumbnail_medium;
    let onError;
    if (video.thumbnail_medium.includes(".dat_m")) {
      // use large thumbnail instead of medium for S3 assets
      largeThumbnail = video.thumbnail_medium.replace(".dat_m", ".dat_l");
      // if that fails, fall back to the thumbnail that we know should exist
      onError = (e) => {
        e.target.src = video.thumbnail_medium;
      };
    }
    return (
      <div style={{ width: "100%", display: "grid", justifyItems: "center" }}>
        <img
          style={{ height: "300px", width: "auto" }}
          src={largeThumbnail}
          onError={onError}
          alt={video.original_filename}
        ></img>
      </div>
    );
  }

  let vWidth = "100%";
  let vHeight = "400px";

  if (mode === "video") {
    if (video.width !== null) {
      if (window.innerWidth <= 768) {
        vWidth = `${window.innerWidth - 110}px`;
        vHeight = `${(video.height * (window.innerWidth - 110)) / video.width}px`;
      } else if (window.innerWidth <= 1366) {
        vHeight = "240px";
      } else {
        vHeight = "400px";
      }
    } else {
      vWidth = "100%";
      vHeight = "auto";
    }
  }

  if (mode === "audio") {
    vHeight = "210px";
    if (window.innerWidth <= 768) {
      vWidth = "100%";
    } else {
      vWidth = "80%";
    }
  }

  if (mode === "folder") {
    vHeight = "200px";
  }

  if (mode === "group" || mode === "stitch") {
    vHeight = "600px";
  }

  if (mode === "other") {
    vHeight = "auto";
    vWidth = "100%";
    if (window.innerWidth > 768) {
      vHeight = "100px";
    }
  }

  let src;

  switch (mode) {
    case "group":
      src = `https://manifestv3.latakoo.com/api/vidplyr/api/latakoo_smart_vidplayer.php?obj_id=${video.videoid}&asset_mode=${mode}&userid=${user.id}&appsource=manifest`;
      break;
    case "stitch":
      src = `https://manifestv3.latakoo.com/api/vidplyr/api/latakoo_smart_vidplayer.php?obj_id=${video.videoid}&asset_mode=${mode}&userid=${user.id}&appsource=manifest`;
      break;
    default:
      const api2BaseUrl = `${isDev ? "dev." : ""}api2.latakoo.com`;
      src = `https://${api2BaseUrl}/media/stream/${video.videoid}?mode=${mode}&access_token=${tokenAccessApi}&userid=${user.id}&appsource=manifest`;
  }

  return (
    <div className="video_container">
      <iframe
        id="ltkplayer"
        title={video.videoid}
        type="text/html"
        width={vWidth}
        height={vHeight}
        src={src}
        frameBorder="0"
        allowFullScreen={true}
        data-type={"video"}
        scrolling={"yes"}
      />
    </div>
  );
};

export default VideoIframe;
