import React, { useRef, useState, useEffect } from "react";
import $ from "jquery";
import { MDBIcon } from "mdbreact";
import { Link } from "react-router-dom";

import { FeedSourceType, useFeed } from "../../contexts/feedContext";
import { useUser } from "contexts/userContext";

function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

  useEffect(() => {
    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isIntersecting;
}

const SidebarMenu = () => {
  const { user, orgsTree } = useUser();

  const { feedConfig, resetFeedData, setFeedConfig } = useFeed();

  const isHomeOrgHighlighted = feedConfig.source === FeedSourceType.HOME_ORGANIZATION;
  const isTopOrgHighlighted = feedConfig.source === FeedSourceType.TOP_ORGANIZATION;

  // const [search, setSearch] = useState('');

  // const [publicMenuOpened, setPublicMenuOpened] = useState((!orgsTree.userHasManifest) ? true : false);
  // const [orgsMenuOpened, setOrgsMenuOpened] = useState((orgsTree.userHasManifest) ? true : false);
  const [publicMenuOpened, setPublicMenuOpened] = useState(
    feedConfig.source === FeedSourceType.CONNECT_ORGANIZATION ? true : false,
  );
  const [orgsMenuOpened, setOrgsMenuOpened] = useState(
    feedConfig.source === FeedSourceType.CONNECT_ORGANIZATION ? false : true,
  );

  function toggleSubmenu(id) {
    $(`.parent-id-${id}`).toggleClass("opened");
  }

  function spctName(name) {
    if (user.top_organization_id === "3093252") {
      return name.replace("Spectrum ", "");
    }

    return name;
  }

  const isOrganizationHighlighted = (organization) => {
    if (organization.public_id_path) {
      if (organization.visibility === "private") {
        return `${organization.id_path}` === `${feedConfig.subject}`;
      } else {
        return `${organization.public_id_path}` === `${feedConfig.subject}`;
      }
    } else if (organization.id_path) {
      return `${organization.id_path}` === `${feedConfig.subject}`;
    } else {
      return `${organization.id}` === `${feedConfig.subject}`;
    }
  };

  const formatOrganizationIdClassName = (organization) =>
    `${organization.id_path ? organization.id_path : organization.id}`.replace(/,/g, "-");

  const renderOrganization = (organization) => {
    const isConnect = organization.public_id_path ? true : false;
    const feedSourceType = isConnect ? FeedSourceType.CONNECT_ORGANIZATION : FeedSourceType.ORGANIZATION;
    const urlPath = isConnect ? "connect-organization" : "organization";
    const hasChildren = organization.children.length > 0;
    const isHighlighted = isOrganizationHighlighted(organization);
    const hasHighlightedChildren = organization.children.some(isOrganizationHighlighted);
    const formatedId = formatOrganizationIdClassName(organization);
    // const idPath = (organization.public_id_path) ? organization.public_id_path : organization.id_path;
    let idPath;
    if (organization.public_id_path) {
      idPath = organization.public_id_path;
    } else if (organization.id_path) {
      idPath = organization.id_path;
    } else {
      idPath = organization.id;
    }
    const emptyLinkDisplay = organization.name === "" ? "none" : "";

    return (
      <li
        className={`${hasChildren ? `has-children parent-id-${formatedId}` : ""}${
          hasHighlightedChildren ? " opened" : ""
        }${isHighlighted ? " menu-link-selected" : ""}`}
        id={formatedId}
        key={formatedId}
        style={{ display: emptyLinkDisplay }}
      >
        {hasChildren && <MDBIcon icon="chevron-down" onClick={() => toggleSubmenu(formatedId)} />}
        {isConnect && organization.visibility === "public" && (
          <Link
            onClick={() => onOrganizationClick(feedSourceType, idPath)}
            to={`/assignment/${urlPath}/${idPath}`}
            title={spctName(organization.name)}
          >
            <span>{spctName(organization.name)}</span>
          </Link>
        )}

        {isConnect && organization.visibility === "private" && (
          <span>
            <MDBIcon icon="lock" /> {spctName(organization.name)}
          </span>
        )}

        {!isConnect && (
          <Link
            onClick={() => onOrganizationClick(feedSourceType, idPath)}
            to={`/assignment/${urlPath}/${idPath}`}
            title={spctName(organization.name)}
          >
            <span>{spctName(organization.name)}</span>
          </Link>
        )}

        {/* {organization.visibility === 'public' &&
          <Link
            onClick={() => onOrganizationClick(feedSourceType, idPath)}
            to={`/assignment/${urlPath}/${idPath}`}
            title={spctName(organization.name)}  
          >
            <span>{spctName(organization.name)}</span>
          </Link>
        }
        {organization.visibility === 'private' &&
          <span><MDBIcon icon="lock" /> {spctName(organization.name)}</span>
        } */}
        <ul className="sidebar-sub-menu">{renderOrganizations(organization.children)}</ul>
      </li>
    );
  };

  const onOrganizationClick = (source, subject = undefined) => {
    setFeedConfig({
      ...feedConfig,
      page: 1,
      source,
      subject,
    });
    resetFeedData();
  };

  const renderOrganizations = (organizationSubtree = []) => organizationSubtree.map(renderOrganization);
  const renderOrganizationsFlat = (children = []) => {
    const flattenChildren = (org) => {
      return [
        { ...org, children: [], public_id_path: org.public_id_path ? org.id : undefined, id_path: undefined },
        ...org.children.flatMap(flattenChildren),
      ];
    };
    return children.flatMap(flattenChildren).map(renderOrganization);
  };

  const menuBottomRef = useRef(null);
  const isVisible = useOnScreen(menuBottomRef);

  // const [searchTerm, setSearchTerm] = useState('');
  // const [searchResults, setSearchResults] = useState([]);
  // const handleChange = event => {
  // 	setSearchTerm(event.target.value);
  // };

  const toggleOrgsSectionSubmenus = (section) => {
    if (section === "publicMenu") {
      setPublicMenuOpened(!publicMenuOpened);
    } else if (section === "orgsMenu") {
      setOrgsMenuOpened(!orgsMenuOpened);
    }
  };

  const showPublicMenu = orgsTree?.public_trees?.children.length > 0;
  const isTopLevelPublicOrgsHighlighted = `${orgsTree?.public_trees.public_id_path}` === `${feedConfig.subject}`;

  return (
    <>
      {/* <div className="sidebar-search">
        <div className="sidebar-search_wrapper">
          <input type="text" placeholder="Filter Organization" id="mysearch" name="mysearch" value={searchTerm} onChange={handleChange}  />
          <button><MDBIcon icon="search" /></button>
        </div>
      </div> */}
      <div className="sidebar-menu-container">
        {showPublicMenu && (
          <>
            <ul className={publicMenuOpened ? "sidebar-menu-public-fixed opened" : "sidebar-menu-public-fixed"}>
              <li className={isTopLevelPublicOrgsHighlighted ? "has-submenu menu-link-selected" : "has-submenu"}>
                <div className="open_close-menu_link" onClick={() => toggleOrgsSectionSubmenus("publicMenu")}>
                  {publicMenuOpened && <MDBIcon icon="minus" />}
                  {!publicMenuOpened && <MDBIcon icon="plus" />}
                </div>
                <Link
                  onClick={() =>
                    onOrganizationClick(FeedSourceType.CONNECT_ORGANIZATION, orgsTree.public_trees.public_id_path)
                  }
                  to={`/assignment/connect-organization/${orgsTree.public_trees.public_id_path}`}
                  title={orgsTree.public_trees.name}
                >
                  <b>
                    <MDBIcon icon="globe-americas" />
                  </b>
                  <span>{orgsTree.public_trees.name}</span>
                </Link>
              </li>
            </ul>
            <ul className={publicMenuOpened ? "sidebar-menu-public opened" : "sidebar-menu-public"}>
              {renderOrganizationsFlat(orgsTree.public_trees.children)}
            </ul>
          </>
        )}
        {/* {orgsTree.userHasManifest &&
        
        } */}
        <ul className={orgsMenuOpened ? "sidebar-menu-fixed opened" : "sidebar-menu-fixed"}>
          <li className={isHomeOrgHighlighted ? "menu-link-selected has-submenu" : "has-submenu"}>
            <Link
              onClick={() => onOrganizationClick(FeedSourceType.HOME_ORGANIZATION)}
              to="/assignment/organization"
              title={spctName(user.organization_name)}
            >
              <b>
                <MDBIcon icon="home" />{" "}
              </b>
              <span>{spctName(user.organization_name)}</span>
            </Link>
          </li>
          <li className={isTopOrgHighlighted ? "has-submenu menu-link-selected" : "has-submenu"}>
            {showPublicMenu && (
              <div className="open_close-menu_link" onClick={() => toggleOrgsSectionSubmenus("orgsMenu")}>
                {orgsMenuOpened && <MDBIcon icon="minus" />}
                {!orgsMenuOpened && <MDBIcon icon="plus" />}
              </div>
            )}

            <Link
              onClick={() => onOrganizationClick(FeedSourceType.TOP_ORGANIZATION)}
              to="/assignment/top-organization"
              title={spctName(user.top_organization_name)}
            >
              <b>
                <MDBIcon icon="sitemap" />
              </b>
              <span>{spctName(user.top_organization_name)}</span>
            </Link>
          </li>
        </ul>
        <ul className={orgsMenuOpened ? "sidebar-menu-orgs opened" : "sidebar-menu-orgs"}>
          {renderOrganizations(orgsTree.org_tree.children)}
          <li ref={menuBottomRef} className="last-li-item"></li>
        </ul>
        {!isVisible && (
          <div className="sidebar-menu-more">
            <MDBIcon icon="angle-down" />
          </div>
        )}
      </div>
    </>
  );
};

export default SidebarMenu;
