import axios from "axios";
import jwt from "jsonwebtoken";
import { DateTime } from "luxon";
import React from "react";
import Cookies from "universal-cookie";

import { useLocalState } from "components/utils/useLocalState";
import LoadingIndicator from "components/loadingIndicator";
import { ReactComponent as NormallatakooBird } from "../images/NormallatakooBird.svg";
import { preloadUserPurchaseNetworkOptions } from "components/modal/purchaseModal";
// TODO: import minutesToMilliseconds

export const UserContext = React.createContext();

const computeUserExpirationDate = () => DateTime.now().plus({ minutes: 30 }).toISO();

export function UserContextProvider({ children }) {
  const searchParams = new URLSearchParams(window.location.search);
  const cookies = React.useMemo(() => new Cookies(), []);

  const [initialLoad, setInitialLoad] = React.useState(true);
  const [isLoadingUser, setIsLoadingUser] = React.useState(false);
  const [isLoadingTrees, setIsLoadingTrees] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);
  const [user, setUser] = useLocalState("user", null);
  const [orgsTree, setOrgsTree] = useLocalState("orgsTree", null);
  const [userExpirationDate, setUserExpirationDate] = useLocalState("userExpirationDate", computeUserExpirationDate());

  const refreshTokenApi = cookies.get("token_refresh_api");
  const [tokenAccessApi, setTokenAccessApi] = React.useState(() => cookies.get("token_access_api"));
  const [userToken, setUserToken] = React.useState(() => jwt.decode(tokenAccessApi));

  const [isMenuOpened, setMenuOpened] = React.useState(false);
  const [isSidebarOpened, setIsSidebarOpened] = React.useState(false);
  const [sidebarPanelState, setSidebarPanelState] = React.useState("opened");
  const [sidebarWidth, setSidebarWidth] = useLocalState("sidebarWidth", 280);

  if (cookies.get("tzCookie") === undefined) {
    cookies.set("tzCookie", Intl.DateTimeFormat().resolvedOptions().timeZone, { path: "/" });
  }

  // TODO: implement "needTokenRefresh"
  // TODO: implement "logoutUser"
  // TODO: implement "refreshTokenAccessApi"
  const tempOrgsTree = user
    ? user.org_tree
    : {
        children: [],
        id: "297973",
        name: "latakoo",
        selected: true,
      };
  const tempTree = {
    org_tree: tempOrgsTree,
    public_trees: {
      children: [],
      id: "297973",
      name: "latakoo",
      selected: true,
    },
  };

  const getUser = React.useCallback(async () => {
    const queryParameters = new URLSearchParams();
    const url = `${process.env.REACT_APP_API_BASE_URL}/user/${userToken.id}`;

    queryParameters.set("ownerid", userToken.id);
    queryParameters.set("tz", cookies.get("tzCookie"));

    if (searchParams.has("sid")) {
      queryParameters.set("sid", searchParams.get("sid"));
    }

    setIsLoadingUser(true);

    try {
      const response = await axios.get(url, {
        params: queryParameters,
      });
      // TODO: set user in Sentry
      setUser(response.data);
      setUserExpirationDate(computeUserExpirationDate());
      tempTree.org_tree = response.data.org_tree;
    } catch (error) {
      console.error(error);
      setHasError(true);
    } finally {
      setIsLoadingUser(false);
    }
  }, [cookies, searchParams, setUser, setUserExpirationDate, userToken]);

  const getOrgsTree = React.useCallback(async () => {
    const url = `${process.env.REACT_APP_USERS_API_LC_BASE_URL}/${userToken.id}/org-trees`;

    setIsLoadingTrees(true);

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${tokenAccessApi}`,
        },
      });
      setOrgsTree(response.data);
    } catch (error) {
      setOrgsTree(tempTree);
      // setHasError(true);
    } finally {
      setIsLoadingTrees(false);
    }
  }, [setOrgsTree, tokenAccessApi, userToken]);

  React.useEffect(() => {
    if (!tokenAccessApi && !userToken) {
      console.log("redirecting to login");
      window.localStorage.clear();
      const url = new URL(process.env.REACT_APP_LOGIN_URL);
      url.searchParams.set("fwd", window.location.href);
      window.location = url;
      return;
    }

    // TODO: refresh token if necessary like on Pilotgen
    getOrgsTree();
    setInitialLoad(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!initialLoad) {
      const isExpired = DateTime.now() > DateTime.fromISO(userExpirationDate);
      // const hasSwitchedUser = userToken?.id !== user?.id;
      const hasSwitchedUser = user ? userToken?.id !== user.id : false;

      if (!isLoadingUser && (isExpired || !user || hasSwitchedUser)) {
        if (hasSwitchedUser) {
          // NOTE: erasing the old User
          console.log("erasing user");
          setUser(null);
        }

        getUser();
      }
    }
  }, [getUser, initialLoad, isLoadingUser, setUser, tokenAccessApi, user, userExpirationDate, userToken]);

  // preload the user's purchase network options to provide good UX when they click the "Purchase" button
  const userId = user?.id; // just doing this to satisfy the dependency array linting rule
  React.useEffect(() => {
    preloadUserPurchaseNetworkOptions(userId, tokenAccessApi);
  }, [userId, tokenAccessApi]);

  function openMenu() {
    setMenuOpened(!isMenuOpened);
  }

  function openSidebar() {
    setIsSidebarOpened(!isSidebarOpened);
  }

  // expose a toggleSidebar function on the window object so that it can be called from the app if we're in a mobile webview
  // this lets us toggle the sidebar using a hamburger icon in the app status bar, which looks way better than cramming a second status bar within the webview to fit the sidebar toggle button
  // openSidebar actually toggles the sidebar :/
  window.toggleSidebar = openSidebar;

  function openSidebarPanel() {
    setSidebarPanelState("opened");
    setSidebarWidth(280);
  }

  function closeSidebarPanel() {
    setSidebarPanelState("closed");
    setSidebarWidth(56);
  }

  const providerValue = {
    closeSidebarPanel,
    hasError,
    initialLoad,
    isLoadingUser,
    isLoadingTrees,
    isMenuOpened,
    isSidebarOpened,
    openMenu,
    openSidebar,
    openSidebarPanel,
    setIsSidebarOpened,
    setMenuOpened,
    setSidebarPanelState,
    setUser,
    setSidebarWidth,
    sidebarPanelState,
    sidebarWidth,
    tokenAccessApi,
    user,
    orgsTree,
  };

  const isLoading = initialLoad || isLoadingTrees || !orgsTree || isLoadingUser || !user;

  return (
    <UserContext.Provider value={providerValue}>
      {isLoading && (
        <div className="initial-loading-container">
          <NormallatakooBird title="Latakoo logo" />
          {/* <h1>We are loading your Manifest</h1> */}
          <h1>
            We are loading latakoo Connect
            <br />
            You are leaving your company's internal latakoo workflow, and will have access to{" "}
            <strong>third-party content</strong>. Follow your organization's guidelines on usage.
          </h1>
          <LoadingIndicator />
        </div>
      )}
      {!isLoading && children}
    </UserContext.Provider>
  );
}

export const useUser = () => React.useContext(UserContext);
