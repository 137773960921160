import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { MDBInput } from "mdbreact";
import AutocompleteAssignedTo from "components/forms/controllers/autocompleteAssignedTo";
import TagsList from "components/forms/controllers/tagsList";
import DateTimePicker from "components/forms/controllers/dateTimePicker";
import Select from "components/forms/controllers/select";
import DragAndDrop from "components/forms/controllers/dragAndDrop";
import SearchLocationInput from "components/forms/controllers/searchLocationInput";
import { useUser } from "contexts/userContext";
import LoadingIndicator from "components/loadingIndicator";
import { FeedSourceType, useFeed } from "contexts/feedContext";

const EditTopicForm = ({ id }) => {
  const { tokenAccessApi } = useUser();
  const { feedConfig, setFeedConfig, resetFeedData } = useFeed();

  const [formFields, setFormFields] = useState([]);
  const [values, setValues] = useState([]);
  const history = useHistory();
  const [hasExpiration, setHasExpiration] = useState("1");
  const [assets, setAssets] = useState([]);
  const [filesValues, setFilesValues] = useState([]);
  const [loadingForm, setLoadingForm] = useState(false);
  const [savedForm, setSavedForm] = useState(false);

  const getForm = useCallback(async () => {
    setLoadingForm(true);
    const url = `${process.env.REACT_APP_TOPICS_API_LC_BASE_URL}/${id}/form`;

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${tokenAccessApi}`,
        },
      });
      if (response.data && response.data.length > 0) {
        setFormFields(response.data);
        setValues(response.data);
        setHasExpiration(response.data[response.data.length - 2].value);
      }
      setLoadingForm(false);
    } catch (error) {
      console.error(error);
      // setHasError(true);
    } finally {
      setLoadingForm(false);
    }
  }, []);

  const saveForm = useCallback(async () => {
    setLoadingForm(true);
    const url = `${process.env.REACT_APP_TOPICS_API_LC_BASE_URL}/${id}`;

    try {
      const response = await axios.put(url, values, {
        headers: {
          Authorization: `Bearer ${tokenAccessApi}`,
        },
      });
      setSavedForm(true);
    } catch (error) {
      console.error(error);
      // setHasError(true);
    } finally {
      setLoadingForm(false);
    }
  }, [values, filesValues]);

  useEffect(() => {
    getForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChange(ev) {
    const { value } = ev.target;
    const index = ev.target.getAttribute("index");
    const newValues = [...values];
    newValues[index]["value"] = value;
    setValues(newValues);
  }

  function onChangeLocation(val, id, i) {
    const newValues = [...values];
    newValues[i]["value"] = val;
    setValues(newValues);
  }

  function onChangeAlt(index, value) {
    if (values.length) {
      const newValues = [...values];
      newValues[index]["value"] = value;
      setValues(newValues);
    }
  }

  function onChangeAltExpDay(index, value) {
    if (values.length) {
      const newValues = [...values];
      newValues[values.length - 1]["value"] = value;
      setValues(newValues);
    }
  }

  function onSubmit(ev) {
    ev.preventDefault();
    saveForm();
    // save({ data: [...values, filesValues] });
  }

  function cancel() {
    history.push("/");
  }

  function onClickRadio(r) {
    setHasExpiration(r);
    if (values.length) {
      const newValues = [...values];
      newValues[newValues.length - 2]["value"] = r;
      setValues(newValues);
    }
  }

  let i = 0;
  let j = 0;

  function handleDrop(files) {
    setFilesValues(files);
    let fileList = assets;

    for (let f = 0; f < files.length; f++) {
      if (files[f].name) {
        fileList = [...fileList, files[f].name];
        setAssets(fileList);
      }
    }
  }

  function uploadChange(e) {
    setFilesValues(e.target.files);
    let files = e.target.files;
    let fileList = assets;

    for (let f = 0; f < files.length; f++) {
      if (files[f].name) {
        fileList = [...fileList, files[f].name];
        setAssets(fileList);
      }
    }
  }

  const singleViewPage = (source, subject = undefined) => {
    setFeedConfig({
      ...feedConfig,
      page: 1,
      source,
      subject,
    });
    resetFeedData();
  };

  return (
    <div className="form-container">
      {loadingForm && <LoadingIndicator />}

      {!loadingForm && savedForm && (
        <div className="success-message">
          <span>
            Success: Topic <strong>{values[0].value}</strong> edited successfully
          </span>
          <span>
            Go to
            <Link to={`/topic/${id}`} onClick={() => singleViewPage(FeedSourceType.SINGLE_TOPIC, id)}>
              <span> topic</span>
            </Link>
          </span>
        </div>
      )}

      {!loadingForm && formFields.length > 0 && (
        <form className="add-topic-form" onSubmit={onSubmit}>
          <div className="form-row form-row-buttons form-top-buttons">
            <button type="submit" className="btn-primary">
              Update Topic
            </button>
          </div>

          <div className="form-fields">
            {formFields.map((formField) => {
              if (formField.type === "topic") {
                return (
                  <div key={i++} className={`order0 form-title`}>
                    {formField.value}
                  </div>
                );
              }
            })}

            {formFields.map((formField, index) => {
              let required = "";
              let inform = formField.config.inform === "0" ? "topic-field" : "assignment-field";
              const nameHtmlEscaped = new DOMParser().parseFromString(formField.name, "text/html").documentElement
                .textContent;
              if (formField.config.required === "1") {
                required = "required";
              }

              if (formField.config.inform === "0") {
                switch (formField.type) {
                  case "topic":
                    return (
                      <div key={i++} className={`order1 ${inform}`}>
                        <MDBInput
                          type="text"
                          label={nameHtmlEscaped}
                          id={formField.id}
                          name={nameHtmlEscaped}
                          required={required}
                          onChange={onChange}
                          index={index}
                          value={formField.value}
                        />
                      </div>
                    );

                  case "assignment":
                    return (
                      <div key={i++} className={`order1 ${inform}`}>
                        <MDBInput
                          type="text"
                          label={nameHtmlEscaped}
                          id={formField.id}
                          name={nameHtmlEscaped}
                          required={required}
                          onChange={onChange}
                          index={index}
                          value={formField.value}
                        />
                      </div>
                    );

                  case "person":
                    return (
                      <AutocompleteAssignedTo
                        className={`order2 ${inform}`}
                        key={i++}
                        onChangeAlt={onChangeAlt}
                        index={index}
                        value={formField.value}
                      />
                    );

                  case "map":
                    return (
                      <SearchLocationInput
                        order={"order2"}
                        key={i++}
                        inform={inform}
                        id={formField.id}
                        required={required}
                        onChangeLocation={onChangeLocation}
                        index={index}
                        initValue={formField.value === null ? "" : formField.value}
                      />
                    );

                  case "text":
                    if (nameHtmlEscaped !== "Has expiration") {
                      return (
                        <div key={i++} className={`order2 ${inform}`}>
                          <MDBInput
                            type="text"
                            label={nameHtmlEscaped}
                            id={formField.id}
                            name={nameHtmlEscaped}
                            required={required}
                            onChange={onChange}
                            index={index}
                            value={formField.value}
                          />
                        </div>
                      );
                    } else {
                      return (
                        <div className="order8 form-row radio-btn-container form-inline" key={i++}>
                          <MDBInput
                            gap
                            onClick={() => onClickRadio("1")}
                            checked={hasExpiration.includes("1")}
                            label="Has Expiration"
                            type="radio"
                            id="radio1"
                            containerClass="mr-5"
                          />
                          <MDBInput
                            gap
                            onClick={() => onClickRadio("0")}
                            checked={hasExpiration.includes("0")}
                            label="No Expiration"
                            type="radio"
                            id="radio0"
                            containerClass="mr-5"
                          />
                        </div>
                      );
                    }

                  case "memo":
                    return (
                      <div key={i++} className={`order2 ${inform}`}>
                        <MDBInput
                          type="textarea"
                          label={nameHtmlEscaped}
                          id={formField.id}
                          name={nameHtmlEscaped}
                          rows="2"
                          onChange={onChange}
                          index={index}
                          required={required}
                          value={formField.value === null ? "" : formField.value}
                        />
                      </div>
                    );

                  case "date":
                    if (nameHtmlEscaped.indexOf("xpiration") === -1) {
                      return (
                        <DateTimePicker
                          index={index}
                          required={required}
                          onChangeAlt={onChangeAlt}
                          className={`form-row form-date-picker order2 ${inform}`}
                          label={nameHtmlEscaped}
                          value={formField.value}
                          key={i++}
                        />
                      );
                    }

                  case "dropdown":
                    if (nameHtmlEscaped === "Tags") {
                      return (
                        <TagsList
                          tags={formField.config.fixedValues}
                          preSelectedTags={formField.value}
                          className={`order2 ${inform}`}
                          key={i++}
                          index={index}
                          required={required}
                          onChangeAlt={onChangeAlt}
                          value={formField.value}
                        />
                      );
                    } else {
                      return (
                        <Select
                          name={nameHtmlEscaped}
                          id={formField.id}
                          options={formField.config.fixedValues}
                          className={`order2 ${inform}`}
                          key={i++}
                          index={index}
                          required={required}
                          onChange={onChange}
                          value={formField.value}
                        />
                      );
                    }

                  case "expiration":
                    return (
                      <DateTimePicker
                        index={index}
                        onChangeAlt={onChangeAltExpDay}
                        className={`form-row form-date-picker order6`}
                        label={"Expiration date"}
                        key={i++}
                        expiration={hasExpiration}
                        value={formField.value !== null ? formField.value : ""}
                      />
                    );
                  default:
                    return (
                      <div key={i++} className={`order6 ${inform}`}>
                        <MDBInput
                          type="text"
                          label={nameHtmlEscaped}
                          id={formField.id}
                          name={nameHtmlEscaped}
                          required={required}
                          index={index}
                          value={formField.value}
                        />
                      </div>
                    );
                }
              }
            })}
          </div>

          <div key={i++} className={`order9 drag-and-drop-block`}>
            <span className="upload-documents-label">Upload document files only (maximum weight: 20 Mb)</span>
            <DragAndDrop handleDrop={handleDrop}>
              <div className="files-container">
                <div className="selected-uploaded-files">
                  {assets.map((file) => (
                    <div key={j++}>{file}</div>
                  ))}
                </div>
                <div className="drop-files-here">
                  Add attachments here
                  <input className="upload-files-input" type="file" onChange={uploadChange} />
                </div>
              </div>
            </DragAndDrop>
          </div>

          <div className="form-row form-row-buttons">
            <button type="button" className="btn-secondary" onClick={cancel}>
              Cancel
            </button>
            <button type="submit" className="btn-primary">
              Update Topic
            </button>
          </div>
        </form>
      )}

      {!loadingForm && formFields.length === 0 && (
        <div className="no-org-form-message">
          <h3>Please contact latakoo support</h3>
          <p>
            Phone: <strong>1-877-882-KOOO (5666)</strong>
          </p>
          <p>
            Email:{" "}
            <strong>
              <a href="mailto:support@latakoo.com">support@latakoo.com</a>
            </strong>
          </p>
        </div>
      )}
    </div>
  );
};

export default EditTopicForm;
