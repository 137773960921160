import React, { useEffect, useState } from "react";
import axios from "axios";

import FeedCard from "components/feed/feedGrid/feedCard";

import { useFeed } from "contexts/feedContext";
import { useUser } from "contexts/userContext";

const FeedGrid = ({ cards, singleTopic }) => {
  const { user } = useUser();
  const { feedGeneralContent, getFeedApiUrl } = useFeed();
  const [newItems, setNewItems] = useState([]);

  useEffect(() => {
    const GetAKeyMode = {
      FIRST: 0,
      LAST: 1,
    };

    const getAKey = (array, mode = GetAKeyMode.FIRST) => {
      switch (mode) {
        case GetAKeyMode.FIRST:
          const [element] = array;

          return element.a_key;
        case GetAKeyMode.LAST:
          return array[array.length - 1].a_key;
        default:
          throw new Error(`Unsupported GetAKeyMode of value "${mode}"`);
      }
    };

    // TODO: move to context in the future
    const fetchNew = () => {
      if (singleTopic) {
        return;
      }

      let aKey;

      if (newItems.length > 0) {
        aKey = getAKey(newItems, GetAKeyMode.LAST);
      } else if (cards.length > 0) {
        aKey = getAKey(cards, GetAKeyMode.FIRST);
      }

      if (aKey) {
        const queryParameters = new URLSearchParams();

        queryParameters.set("ownerid", user.id);
        queryParameters.set("a_key", aKey);
        queryParameters.set("include-gc", feedGeneralContent);
        queryParameters.set("_order", "-1");
        queryParameters.set("random", Math.floor(Math.random() * 1e16));

        axios
          .get(getFeedApiUrl(), { params: queryParameters })
          .then((response) => {
            // FIXME: don't use previous items?
            setNewItems([...response.data, ...newItems]);
          })
          .catch(console.error);
      }
    };

    const fetchMoreInterval = setInterval(() => {
      console.log("grid fetchNew axios");
      fetchNew();
    }, 30000);

    return () => clearInterval(fetchMoreInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCard = (cardData, index) => <FeedCard card={cardData} key={index} opened={singleTopic} />;

  const renderCards = (cardsData) => cardsData.map(renderCard);

  return (
    <div>
      <div className="grid-view">
        <div className="newest-cards">{!singleTopic && renderCards(newItems)}</div>

        {renderCards(cards)}
      </div>
    </div>
  );
};

export default FeedGrid;
