import React from "react";
import { MDBIcon } from "mdbreact";

const FormHeader = ({ title }) => {
  return (
    <div className="form-header">
      <div className="form-header_info">
        <h1>{title}</h1>
        {/* <ul className="form-breadcrumb">
					<li key="1"><a href="/">Home</a></li>
					<li key="2"><a href="/">Topics / Assignments</a></li>
					<li key="3" className="breadcrumb-current-organization"><MDBIcon icon="plus-circle" /> {title}</li>
				</ul> */}
      </div>
    </div>
  );
};

export default FormHeader;
