import React, { useState } from "react";
import axios from "axios";
import { MDBIcon } from "mdbreact";
import { useUser } from "contexts/userContext";

const VideoGeoLocation = ({ video }) => {
  const { user } = useUser();
  const [geoLocationData, setGeoLocationData] = useState(null);

  const getGeoLocationData = React.useCallback(async () => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/video/${video.videoid}/geo/${video.remote_ip}`;
    const queryParameters = new URLSearchParams();
    queryParameters.set("ownerid", user.id);

    try {
      const response = await axios.get(url, { params: queryParameters });
      setGeoLocationData(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [user, video]);

  return (
    <dl className="video_geo-location">
      <dt onClick={getGeoLocationData}>
        <MDBIcon icon="map-marker-alt" /> Uploaded from
      </dt>
      <dd>
        {geoLocationData !== null && (
          <div>
            {/* {geoLocationData.as &&
                            <p>
                                ASN: {geoLocationData.as.asn} | 
                                Name {geoLocationData.as.name} | 
                                Type {geoLocationData.as.type} | 
                                Route {geoLocationData.as.route} | 
                                Domain {geoLocationData.as.domain}
                            </p>
                        }
                        
                        {geoLocationData.ip || geoLocationData.isp &&
                            <p>
                                IP: {geoLocationData.ip} | 
                                ISP: {geoLocationData.isp}
                            </p>
                        }                       
                        
                        {geoLocationData.proxy &&
                            <p>
                                TOR: {geoLocationData.proxy.top} | 
                                VPN: {geoLocationData.proxy.vpn} | 
                                PROXY: {geoLocationData.proxy.proxy}
                            </p>
                        }
                        
                        {geoLocationData.domains &&
                            <p>Domains: <br />
                                {geoLocationData.domains.map((dm, i) => (
                                    <span key={i}>{dm}</span>
                                ))}
                            </p>
                        } */}

            {geoLocationData.location && (
              <p>
                Region: {geoLocationData.location.region} | City: {geoLocationData.location.city} |
                <a
                  href={`https://maps.google.com/?q=${geoLocationData.location.lat},${geoLocationData.location.lng}`}
                  target="_blank"
                >
                  Map <MDBIcon icon="external-link-alt" />
                </a>
              </p>
            )}
          </div>
        )}
      </dd>
    </dl>
  );
};

export default VideoGeoLocation;
