import React from "react";
import Assignment from "components/feed/feedGrid/assignment";
import { MDBIcon } from "mdbreact";
import LoadingIndicator from "components/loadingIndicator";
import { Link } from "react-router-dom";
import { FeedSourceType, useFeed } from "contexts/feedContext";

const Assignments = ({ assignments, topic, isOwner, isGeneralContent }) => {
  const { feedConfig } = useFeed();

  if (!assignments) {
    return <LoadingIndicator />;
  }

  const isConnect =
    feedConfig.source === FeedSourceType.CONNECT_SINGLE_TOPIC ||
    feedConfig.source === FeedSourceType.CONNECT_ORGANIZATION
      ? true
      : false;

  return (
    <div className="assignments-container">
      {isGeneralContent && <div className="assignments-title">Assets</div>}

      {!isGeneralContent && (
        <div className="assignments-title">
          {isConnect ? "Content" : "Assignments"}
          {isOwner && (
            <Link className="card-add-btn" to={`/addassignment/${topic.id}/${encodeURIComponent(topic.title)}`}>
              <MDBIcon icon="plus" />
            </Link>
          )}
        </div>
      )}

      <ul className={assignments.length > 3 ? "assignments-list scroll-assignment" : "assignments-list"}>
        {assignments.map((assignment) => (
          <Assignment
            topic={topic}
            assignment={assignment}
            key={assignment.id}
            isOwner={isOwner}
            cardId={topic.id}
            assetTypes={topic.assignment_video_types}
          />
        ))}
      </ul>
    </div>
  );
};

export default Assignments;
