import { useSettings } from "contexts/settingsContext";
import React from "react";

const FontSizeButton = () => {
  const { increaseFontSize, decreaseFontSize } = useSettings();

  return (
    <div className="font_size-button">
      <em>Font size:</em>
      <span onClick={increaseFontSize}>A+</span>
      <span onClick={decreaseFontSize}>A-</span>
    </div>
  );
};

export default FontSizeButton;
