import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import AssignmentsList from "components/feed/feedGrid/assignmentsList";
import DeleteTopicModal from "components/modal/deleteTopicModal";
import LocationModal from "components/modal/locationModal";
import { MDBIcon } from "mdbreact";
import $ from "jquery";
import { useUser } from "contexts/userContext";
import CardHeaderThumbnail from "./cardHeaderThumbnail";
import { FeedSourceType, useFeed } from "contexts/feedContext";
import PublishModal from "components/modal/publishModal";
import CardRequest from "../controllers/cardRequest";
import { centsToDollarString } from "components/utils/priceString";
import PurchaseModal from "components/modal/purchaseModal";

const priceToText = (price) => (price === 0 ? "Free" : centsToDollarString(price));

const FeedCard = ({ card, opened }) => {
  const { user, tokenAccessApi, orgsTree } = useUser();
  const {
    feedConfig,
    // resetFeedData,
    // setFeedConfig
  } = useFeed();

  const [cardId, setCardId] = useState(opened ? card.id : null);
  const [isOpened, setOpened] = useState(opened);

  const isConnect =
    feedConfig.source === FeedSourceType.CONNECT_SINGLE_TOPIC ||
    feedConfig.source === FeedSourceType.CONNECT_ORGANIZATION;

  const priceMin = card.purchaseOptions?.length ? Math.min(...card.purchaseOptions.map((option) => option.price)) : 0;
  const priceMax = card.purchaseOptions?.length ? Math.max(...card.purchaseOptions.map((option) => option.price)) : 0;
  const priceText =
    priceMin === priceMax ? priceToText(priceMin) : `${priceToText(priceMin)} - ${priceToText(priceMax)}`;

  const hasPurchaseOptions = !!card.purchaseOptions?.length;
  const isPurchase = hasPurchaseOptions && isConnect;
  const hasActiveLicense = card.activeLicenses?.length > 0;

  function onClick(ev) {
    ev.preventDefault();
    setCardId(card.id);
    setOpened(!isOpened);
  }

  function clickOpenArea(cardId) {
    $(`#${cardId}`).find(".card-toggle-btn").trigger("click");
  }

  const [following, setFollowing] = useState(card.isFollowing);
  const [buttonLoading, setButtonLoading] = useState(false);

  // TODO: move to feedContext in the future
  const clickFollow = (assignmentId) => {
    setButtonLoading(true);

    const mode = following ? "10" : "8";

    axios
      .post(
        `${process.env.REACT_APP_GATEWAY_API_BASE_URL}?mode=${mode}&assignmentid=${assignmentId}&org_id=${user.organization_id}&userid=${user.id}&tz=${user.tz}`,
      )
      .then(function (response) {
        setButtonLoading(false);
        setFollowing(following ? false : true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [topicDelete, setTopicDelete] = useState(false);

  const [showLocation, setShowLocation] = useState(false);
  const [mapLocation, setMapLocation] = useState("");
  const [showMapMobile, setShowMapMobile] = useState(false);

  function showMapModal(location) {
    setMapLocation(location);
    setShowLocation(!showLocation);
  }

  const generalContent = card.general_content ? true : false;

  const gcFlag = card.isGeneralContent ? card.isGeneralContent : false;

  let isOwner = card.org_id === user.organization_id || card.admin === 1 || card.super_admin === 1 ? true : false;

  const createMarkup = (html) => {
    return { __html: html };
  };

  const titleHtml = card.title !== null ? card.title.replace(/_/g, "_<span></span>") : "";

  const [showPublishPrompt, setShowPublishPrompt] = useState(false);
  const [published, setPublished] = useState(card.visibility === "public" ? true : false);
  const [publishing, setPublishing] = useState(false);
  const [showRequestDropdown, setShowRequestDropdown] = useState(false);
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);

  const requestOrPurchase = () => {
    if (isPurchase) {
      setShowPurchaseModal(!showPurchaseModal);
    } else {
      setShowRequestDropdown(!showRequestDropdown);
    }
  };

  /*const publish = () => {
    setPublishing(true)

    const visibilityObject = {
      visibility: (published) ? 'private' : 'public'
    }

    axios
			.patch(`${process.env.REACT_APP_TOPICS_API_LC_BASE_URL}/${card.id}/visibility`, visibilityObject, {
        headers: {
          Authorization: `Bearer ${tokenAccessApi}`,
        },
      })
			.then(function(response) {
        setPublished(!published)
        setPublishing(false)
			})
			.catch(function(error) {
        setPublishing(false)
			  console.log(error);
		});
  }
  */

  // const onTitleClick = (source, subject = undefined) => {
  //   setFeedConfig({
  //     ...feedConfig,
  //     page: 1,
  //     source,
  //     subject,
  //   });
  //   resetFeedData();
  // };

  let orgId;
  if (feedConfig.source === "home-organization") {
    orgId = user.organization_id;
  } else if (feedConfig.source === "top-organization") {
    orgId = card.org_id; //user.top_organization_id;
  } else {
    orgId = feedConfig.subject;
  }

  let orgVisibility = false;

  const checkOrgVisibilityRecursive = (node) => {
    node.map((org) => {
      if (org.id === parseInt(orgId)) {
        orgVisibility = org.visibility === "public" ? true : false;
        return true;
      } else if (org.children.length > 0) {
        checkOrgVisibilityRecursive(org.children);
      }
    });
  };

  if (orgsTree.org_tree.id === orgId) {
    orgVisibility = orgsTree.org_tree.visibility === "public" ? true : false;
  } else {
    checkOrgVisibilityRecursive(orgsTree.org_tree.children);
  }

  return (
    <div
      className={
        isOpened
          ? `card cardId-${card.id} card-opened ${isConnect ? "is-connect-card" : ""}`
          : `card cardId-${card.id}  ${isConnect ? "is-connect-card" : ""}`
      }
      id={card.id}
      key={card.id}
    >
      <div className={gcFlag ? `card-header general-content` : `card-header`}>
        <div className="card-header_infos">
          <div className="card_header-thumbnail">
            <CardHeaderThumbnail card={card} gcFlag={gcFlag} clickOpenArea={clickOpenArea} />
          </div>
          <div className="card-header_title">
            <h2>
              <a
                href={`/${isConnect ? "connect-topic" : "topic"}/${card.id}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <em dangerouslySetInnerHTML={createMarkup(titleHtml)}></em> <MDBIcon icon="external-link-alt" />
              </a>
            </h2>

            <div className="assignment-info">
              {isConnect && card.org_icon && (
                <div className="organization_logo">
                  <img alt="Creator profile" src={JSON.parse(card.org_icon)} />
                </div>
              )}
              <div className="assignment_info-metadata">
                {card.org_name !== undefined && (
                  <strong>
                    <a href={`/assignment/organization/${card.org_id}`}>{card.org_name}</a>
                  </strong>
                )}
                <span>Updated : {card.datechanged}</span>

                <span>
                  {card.cntVideos > 0 && (
                    <span>
                      {card.cntVideos} {card.cntVideos === 1 ? "video" : "videos"}
                    </span>
                  )}
                  {card.cntVideos < 1 && <span>Waiting</span>}
                  {!isConnect && (
                    <a
                      href={`https://manifestv3.latakoo.com/api/tmpLog/?topicid=${card.id}&assignmentid=`}
                      className="download_logs-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      | Download logs <MDBIcon icon="arrow-down" />
                    </a>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
        {window.innerWidth > 1080 && (
          <div className="card_open-area-middle" onClick={() => clickOpenArea(card.id)}>
            &nbsp;
          </div>
        )}

        <div className={`card-header_controls ${isConnect ? "card_header_controls-connect" : ""}`}>
          <div className="card_header-controls_controls">
            {feedConfig.source !== FeedSourceType.CONNECT_SINGLE_TOPIC &&
              feedConfig.source !== FeedSourceType.CONNECT_ORGANIZATION &&
              orgVisibility && (
                // <button onClick={() => publish()} className={(published) ? 'btn-published': 'btn-publish'}>
                //   {publishing &&
                //     <MDBIcon icon="spinner" />
                //   }
                //   {!publishing &&
                //     <span>{(published) ? 'published' : 'publish'} </span>
                //   }
                // </button>
                <button
                  onClick={() => setShowPublishPrompt(true)}
                  className={published ? "btn-published" : "btn-publish"}
                >
                  {publishing && <MDBIcon icon="spinner" />}
                  {!publishing && <span>{published ? "unpublish" : "publish"} </span>}
                </button>
              )}

            {showPublishPrompt && (
              <PublishModal
                onClickClose={() => setShowPublishPrompt(false)}
                card={card}
                published={published}
                setPublished={setPublished}
                publishing={publishing}
                setPublishing={setPublishing}
              />
            )}

            {/* we hide the Request button if the topic has purchase options and this isn't a Connect view,
                because we currently don't support requesting first-party content from Manifest that
                is available for purchase in Connect. */}
            {!generalContent && !hasActiveLicense && !(hasPurchaseOptions && !isConnect) && (
              <div className="card-request-container">
                <button type="button" className="card-request-btn" title="request" onClick={requestOrPurchase}>
                  {/* <MDBIcon icon="clone" /> */} <span>{isPurchase ? "Purchase" : "Request"}</span>
                </button>
                {showRequestDropdown && (
                  <CardRequest card={card} parentId={""} setShowRequestDropdown={setShowRequestDropdown} />
                )}
              </div>
            )}

            {!generalContent && (
              <button
                type="button"
                className={following ? `btn-follow unfollow` : `btn-follow`}
                id={following ? `ajax-target_uf${card.id}` : `ajax-target_${card.id}`}
                onClick={() => clickFollow(card.id)}
              >
                {buttonLoading && <MDBIcon icon="spinner" />}
                {!buttonLoading && <MDBIcon icon="eye" />}
              </button>
            )}

            <button type="button" className="card-toggle-btn" onClick={onClick}>
              <MDBIcon icon="chevron-down" />
            </button>
          </div>

          {isConnect && (
            <div className="card_header-controls_warning" style={{ flexDirection: "column" }}>
              <p>
                <strong>Third-party content</strong>
              </p>
              {/* <MDBIcon icon="exclamation-triangle" /> */}
              <p>
                <strong>{hasActiveLicense ? "Your license is currently active" : priceText}</strong>
              </p>
            </div>
          )}
        </div>
      </div>
      {window.innerWidth > 1080 && <div className="card_open-area-bottom" onClick={() => clickOpenArea(card.id)}></div>}

      <div className="card-body">
        {isOwner && (
          <div className="card-body_controls">
            <Link className="card-add-btn" to={`/addassignment/${card.id}/${encodeURIComponent(card.title)}`}>
              <MDBIcon icon="plus" />
            </Link>

            <Link className="card-edit-btn" to={`/edittopic/${card.id}`}>
              <MDBIcon icon="pencil-alt" />
            </Link>

            {!hasPurchaseOptions && (
              <button
                type="button"
                className="card-delete-btn"
                title="delete"
                onClick={() => setTopicDelete(!topicDelete)}
              >
                <MDBIcon far icon="trash-alt" />
              </button>
            )}
          </div>
        )}
        {topicDelete && !hasPurchaseOptions && (
          <DeleteTopicModal
            onClickClose={() => setTopicDelete(false)}
            id={card.id}
            userId={user.id}
            orgId={user.organization_id}
          />
        )}

        <div className="card-body_info">
          <div className="card-body_info-fields">
            {card.FormField &&
              // eslint-disable-next-line
              card.FormField.filter((field) => field.value).map((field, i) => {
                if (!gcFlag) {
                  return (
                    <dl key={i++} className={field.type}>
                      <dt>{field.label}</dt>
                      <dd className={field.value === null ? "empty" : ""}>{field.value}</dd>
                    </dl>
                  );
                }
              })}

            <dl className={gcFlag ? "hide" : ""}>
              <dt>Location</dt>
              {window.innerWidth > 768 && (
                <dd>
                  <span className="link-text" onClick={() => showMapModal(card.location)}>
                    {card.location}
                  </span>
                </dd>
              )}
              {window.innerWidth <= 768 && (
                <dd>
                  <span className="link-text" onClick={() => setShowMapMobile(!showMapMobile)}>
                    {card.location}
                  </span>
                </dd>
              )}
            </dl>
            {window.innerWidth <= 768 && showMapMobile && (
              <div className="map_location-mobile">
                <iframe
                  width="100%"
                  height="300"
                  frameBorder="0"
                  title="Location"
                  src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAseIjfHwEFrDmM8VDjDvkFp1OPZ64kwVI&q=${card.location}&zoom=8`}
                ></iframe>
              </div>
            )}

            <dl className={gcFlag ? "hide" : ""}>
              <dt>Tags</dt>
              <dd className="card-body_info-tags">
                {card.tags !== null &&
                  card.tags !== "null" &&
                  Object.keys(JSON.parse(card.tags)).map((key) => (
                    <span key={key}>{JSON.parse(card.tags)[key].replace("&amp;", "&")}</span>
                  ))}
              </dd>
            </dl>
            <dl className={gcFlag ? "hide" : ""}>
              <dt>ASSIGNED TO</dt>
              <dd>
                {card.assignedto.length > 0 &&
                  card.assignedto.map((assignedto, i) => (
                    <a key={i} href={`mailto:${assignedto.email}`} title={assignedto.email}>
                      {assignedto.name}
                    </a>
                  ))}
                {card.assignedto.length <= 0 && <span>None Selected</span>}
              </dd>
            </dl>
            <dl className={gcFlag ? "hide" : ""}>
              <dt>REQUESTED BY</dt>
              <dd>
                {card.cntRequesting > 0 &&
                  card.requesting.map((requesting, i) => (
                    <a key={i} href={`mailto:${requesting.user.u_email}`} title={requesting.user.u_email}>
                      {requesting.user.u_name}
                    </a>
                  ))}
                {card.cntRequesting < 1 && <span>No Requesters</span>}
              </dd>
            </dl>
            <dl className={gcFlag ? "hide" : ""}>
              <dt>FOLLOWED BY</dt>
              <dd>
                {card.cntFollowing > 0 &&
                  card.following.map((following, i) => (
                    <a key={i} href={`mailto:${following.user.u_email}`} title={following.user.u_email}>
                      {following.user.u_name}
                    </a>
                  ))}
                {card.cntFollowing < 1 && <span>No Followers</span>}
              </dd>
            </dl>
            {card.expirationdate !== null && (
              <dl className="">
                <dt>Expiration date:</dt>
                <dd>
                  <span className="">{card.expirationdate}</span>
                </dd>
              </dl>
            )}
            {card.u_name && (
              <dl className="card-body_info-creator">
                <dt>
                  <div className="card-creator-image">
                    <img alt="Creator profile" src={card.u__icon} />
                  </div>{" "}
                  CREATOR
                </dt>
                <dd>
                  <a href={`mailto:${card.u_email}`} title={card.u_email}>
                    {card.u_name}
                  </a>
                </dd>
              </dl>
            )}
          </div>
          {card.cntDocFiles > 0 && card.docFiles.length > 0 && (
            <div className="card-body_info-attached-files">
              <h4> Attached Files </h4>
              <table>
                <thead>
                  <tr>
                    <td></td>
                    <td>File Name</td>
                    <td>Date</td>
                    <td>Size</td>
                    <td>Entered By</td>
                  </tr>
                </thead>
                <tbody>
                  {card.docFiles.map((file, i) => (
                    <tr key={i}>
                      <td>
                        <MDBIcon far icon="trash-alt" id={`doc_file_${file.id}`} />
                      </td>
                      <td>{file.origfilename}</td>
                      <td>{file.dateentered}</td>
                      <td>{file.filesize}</td>
                      <td>{file.u_name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

        {cardId && <AssignmentsList topic={card} isOwner={isOwner} isGeneralContent={gcFlag} />}

        {showLocation && <LocationModal onClickClose={() => setShowLocation(false)} mapLocation={mapLocation} />}
      </div>

      {showPurchaseModal && <PurchaseModal topic={card} close={() => setShowPurchaseModal(false)} />}
    </div>
  );
};

export default FeedCard;
