// I'm just hardcoding a bunch of config in the frontend here for now
// obviously this stuff should be configured in settings stored in the database somewhere,
// but we don't want to invest more in the current version of Connect right now, just get this feature out the door and working well enough for SWNS
// we'll do this right in Connect v2, hopefully

const SWNS_ORG_ID = 14181621;
const SWNS_MATERIALS_LICENSE_ID = 9;

const LATAKOO_CONTENT_ORG_ID = 13002931;
const LATAKOO_CONTENT_SWNS_LICENSE_TEST_ID = 8;

export const CONNECT_PURCHASE_CONFIG = {
  [SWNS_ORG_ID]: {
    extraPurchaseModalText: `For more information or monthly subscription options, please contact video@swns.com`,
    defaultPurchaseOptions: [
      // National - 24 hour - $500
      {
        name: "National - 24 hour",
        description: "",
        price: 50000, // price is in cents
        duration: 24, // duration is in hours
        license_id: SWNS_MATERIALS_LICENSE_ID,
      },
      // National - 1 year - $600
      {
        name: "National - 1 year",
        description: "",
        price: 60000,
        duration: 365 * 24,
        license_id: SWNS_MATERIALS_LICENSE_ID,
      },
      // National - 5 years - $650
      {
        name: "National - 5 years",
        description: "",
        price: 65000,
        duration: 5 * 365 * 24,
        license_id: SWNS_MATERIALS_LICENSE_ID,
      },
      // National - In perpetuity - $700
      {
        name: "National - In perpetuity",
        description: "",
        price: 70000,
        duration: 0,
        license_id: SWNS_MATERIALS_LICENSE_ID,
      },
      // National & Affiliate - 24 hours - $700
      {
        name: "National & Affiliate - 24 hours",
        description: "",
        price: 70000,
        duration: 24,
        license_id: SWNS_MATERIALS_LICENSE_ID,
      },
      // National & Affiliate - 1 year - $800
      {
        name: "National & Affiliate - 1 year",
        description: "",
        price: 80000,
        duration: 365 * 24,
        license_id: SWNS_MATERIALS_LICENSE_ID,
      },
      // National & Affiliate - 5 years - $900
      {
        name: "National & Affiliate - 5 years",
        description: "",
        price: 90000,
        duration: 5 * 365 * 24,
        license_id: SWNS_MATERIALS_LICENSE_ID,
      },
      // National & Affiliate - In perpetuity - $1,000
      {
        name: "National & Affiliate - In perpetuity",
        description: "",
        price: 100000,
        duration: 0,
        license_id: SWNS_MATERIALS_LICENSE_ID,
      },
    ],
  },
};

// for testing purposes
CONNECT_PURCHASE_CONFIG[LATAKOO_CONTENT_ORG_ID] = {
  //   extraPurchaseModalText: "This is where optional extra purchase modal text shows up.",
  defaultPurchaseOptions: CONNECT_PURCHASE_CONFIG[SWNS_ORG_ID].defaultPurchaseOptions.map((option) => ({
    ...option,
    license_id: LATAKOO_CONTENT_SWNS_LICENSE_TEST_ID,
  })),
};
