import React, { useRef, useState, useLayoutEffect } from "react";
// import VideoPlayer from 'components/feed/assetType/videoPlayer'
import "video.js/dist/video-js.css";
import axios from "axios";
import DeleteVideoModal from "components/modal/deleteVideoModal";
import AssignmentVideoModal from "components/modal/assignmentVideoModal";
import { MDBIcon } from "mdbreact";
import { useUser } from "contexts/userContext";
import VideoIframe from "./videoIframe";
import { useFeed, FeedSourceType } from "contexts/feedContext";
import { formatBytes, formatSeconds, checkForComma } from "components/utils/formatMeasures";
import LoadingIndicator from "components/loadingIndicator";

const Video = ({ video, cardId, assignmentId, userId, orgId, assetTypes }) => {
  const { feedConfig, setFeedConfig } = useFeed();

  let assetType;

  for (const key in assetTypes) {
    if (parseInt(key) === assignmentId) {
      assetTypes[key].map((asset) => {
        if (asset.id === video.videoid) {
          assetType = asset.asset_type;
        }
      });
    }
  }

  const { tokenAccessApi, user } = useUser();
  const videoContainerRef = useRef("null");
  const [dimensions, setDimensions] = useState({ width: 0 });

  useLayoutEffect(() => {
    if (videoContainerRef.current) {
      setDimensions({
        width: videoContainerRef.current.offsetWidth,
      });
    }
  }, []);

  const [videoDelete, setVideoDelete] = useState(false);
  const [showVideo, setShowVideo] = useState(false);

  function showVideoModal(id) {
    setVideoNtId(id);
    setShowVideo(!showVideo);
  }

  const [videoNetworksDrop, setVideoNetworksDrop] = useState(false);
  const [videoNetworks, setVideoNetworks] = useState(null);
  const [videoNtId, setVideoNtId] = useState(null);

  const getVideoNetworks = React.useCallback(
    async (videoId, tid) => {
      const url = `${process.env.REACT_APP_TOPICS_API_LC_BASE_URL}/asset/${video.videoid}/networks`;

      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${tokenAccessApi}`,
          },
        });
        setVideoNetworks(response.data);
        setVideoNetworksDrop(true);
      } catch (error) {
        console.error(error);
      }
    },
    [user, video],
  );

  const clickNetworksDropdown = () => {
    if (videoNetworks) {
      setVideoNetworksDrop(!videoNetworksDrop);
    } else {
      setVideoNetworksDrop(true);
      getVideoNetworks(video.videoid, video.tid);
    }
  };

  const isConnect =
    feedConfig.source === FeedSourceType.CONNECT_SINGLE_TOPIC ||
    feedConfig.source === FeedSourceType.CONNECT_ORGANIZATION;

  return (
    <div className="video-container" ref={videoContainerRef}>
      <div className="video-container_video">
        {/* <VideoPlayer video={video} dimensions={dimensions} /> */}
        {assetType !== undefined && <VideoIframe video={video} cardId={cardId} mode={assetType} />}
        {assetType === undefined && <div className="assignment_video-container">No Assets</div>}
      </div>
      <div className="video-container_info">
        <div className="video-container_info-user">
          <div className="video-info_user-avatar">
            <span>
              <img src={video.u__icon ? video.u__icon : "//latakoo.com/hif/images/latakoowhite.png"} alt="user name" />
            </span>
          </div>
          <div className="video-info_user-details">
            <span className="video-info_user-details_uploaded">
              Uploaded By: <a href={`mailto: ${video.u_email}`}>{video.u_name}</a>{" "}
              {video.u_home_name ? " | " + video.u_home_name : ""}{" "}
            </span>
            <span className="video-info_user-details_details">
              Video Details: {formatBytes(video.size)}
              {checkForComma(video.size, video.length)} {formatSeconds(video.length)}
            </span>
            <span className="video-info_networks" onClick={() => clickNetworksDropdown(video.videoid, video.tid)}>
              Networks {videoNetworksDrop ? <MDBIcon icon="chevron-up" /> : <MDBIcon icon="chevron-down" />}
            </span>
            {videoNetworksDrop && videoNetworks && (
              <span className="video-info_user-details_networks-drop">
                {videoNetworks.map((nt, i) => {
                  return (
                    <div key={i} onClick={() => showVideoModal(nt.videoId)}>
                      {nt.networkName}
                    </div>
                  );
                })}
              </span>
            )}
            {videoNetworksDrop && !videoNetworks && <LoadingIndicator />}
          </div>
        </div>
        {!isConnect && (
          <button type="button" className="assignment_video-delete" onClick={() => setVideoDelete(!videoDelete)}>
            <MDBIcon far icon="trash-alt" />
            Delete
          </button>
        )}
      </div>

      {showVideo && (
        <AssignmentVideoModal onClickClose={() => setShowVideo(false)} cardId={cardId} videoId={videoNtId} />
        // <AssignmentVideoModal onClickClose={() => setShowVideo(false)} cardId={cardId} videoId={video.videoid}  />
      )}

      {videoDelete && (
        <DeleteVideoModal
          onClickClose={() => setVideoDelete(false)}
          videoId={video.videoid}
          assignmentId={assignmentId}
          userId={userId}
          orgId={orgId}
        />
      )}
    </div>
  );
};

export default Video;
