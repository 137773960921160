import React, { useEffect, useState } from "react";
import axios from "axios";
import Modal from "pages/manifest/modal";
import { centsToDollarString } from "components/utils/priceString";
import Select from "components/forms/controllers/select";
import { useUser } from "contexts/userContext";
import { useFeed } from "contexts/feedContext";
import { CONNECT_PURCHASE_CONFIG } from "constants/connect-purchase-config";

// super lazy way of preloading and caching the user's network options. this is another quick solution to just get this feature out the door
// I'm only bothering to do this because the call to api-lc /users/{id} is quite slow in my testing, which leads to a bad user experience
// so preloading this and caching it is an easy way to make it faster, even if it is quite hacky
let cachedUserNetworkOptions = null;
let cachedUserNetworkOptionsId = null;
const loadUserNetworkOptions = async (userId, tokenAccessApi) => {
  if (cachedUserNetworkOptionsId === userId) {
    return cachedUserNetworkOptions;
  }

  // so this is another quick and dirty hack
  // we need to restrict the network options for a purchase to only the user's home networks
  // the user.network_list that we are currently getting back from manifestv4 does not give us enough information to determine
  // whether or not a network is a child of the current user's home organization or not,
  // so we make a call to the api-lc users service to get mostly-redundant information
  // that will let us determine whether or not a network is a home network
  // we'll fix all of this in Connect v2 relatively soon hopefully, but for now this works well enough
  try {
    const url = new URL(process.env.REACT_APP_USERS_API_LC_BASE_URL + `/${userId}`);
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${tokenAccessApi}`,
      },
    });
    const networkOptions = response.data.network_list
      .filter((network) => network.is_home_network)
      .filter(userHasWritePermission)
      .map((network) => ({ value: network.company_id, label: network.company_name }))
      .sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
    cachedUserNetworkOptions = networkOptions;
    cachedUserNetworkOptionsId = userId;
    return networkOptions;
  } catch (err) {
    console.error("error while loading user network options:", err);
    cachedUserNetworkOptions = null;
    cachedUserNetworkOptionsId = null;
    throw err;
  }
};
// this is currently only called once, in a useEffect in the userContext
let fetchingUserNetworkOptions = false;
export const preloadUserPurchaseNetworkOptions = async (userId, tokenAccessApi) => {
  if (!userId || !tokenAccessApi) return;
  if (fetchingUserNetworkOptions) return;
  fetchingUserNetworkOptions = true;
  try {
    await loadUserNetworkOptions(userId, tokenAccessApi);
  } finally {
    // we can ignore the error, it's handled in loadUserNetworkOptions and we'll lazily retry when we need network options
    fetchingUserNetworkOptions = false;
  }
};

const userHasWritePermission = (network) => network && (network.write === 1 || network.admin === 1);

const maybeS = (count) => (count === 1 ? "" : "s");

const getDurationString = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  if (hours > 72) {
    let result = `${days} day${maybeS(days)}`;
    if (hours % 24 > 0) {
      result += ` ${hours % 24} hour${maybeS(hours % 24)}`;
    }
    return result;
  }
  if (hours > 0) {
    let result = `${hours} hour${maybeS(hours)}`;
    if (minutes % 60 > 0) {
      result += ` ${minutes % 60} minute${maybeS(minutes % 60)}`;
    }
    return result;
  }
  if (minutes > 0) {
    return `${minutes} minute${maybeS(minutes)}`;
  }
  return `${seconds} second${maybeS(seconds)}`;
};

const PurchaseModal = ({ topic, close }) => {
  const { user, tokenAccessApi } = useUser();
  const { setFeedData } = useFeed();

  const [loadingNetworkOptions, setLoadingNetworkOptions] = useState(!cachedUserNetworkOptions);
  const [networkOptions, setNetworkOptions] = useState(cachedUserNetworkOptions ?? []);
  useEffect(() => {
    (async () => {
      // it's fine to unconditionally do this, it's effectively a no-op if we have cached network options
      try {
        const userNetworkOptions = await loadUserNetworkOptions(user.id, tokenAccessApi);
        setNetworkOptions(userNetworkOptions);
        setLoadingNetworkOptions(false);
      } catch (err) {
        // really quick and dirty error handling; I don't expect this to ever happen tbh
        alert("Error while loading purchase options. Please try again later or contact customer support.");
      }
    })();
  }, [user.id, tokenAccessApi]);

  const networkNameMap = Object.fromEntries(networkOptions.map((option) => [option.value, option.label]));

  const [selectedNetwork, setSelectedNetwork] = useState(networkOptions[0]?.value);
  const [selectedPurchaseOptionId, setSelectedPurchaseOptionId] = useState(topic.purchaseOptions[0].id);
  const [viewLicense, setViewLicense] = useState(false);

  const selectedPurchaseOption = topic.purchaseOptions.find(
    (option) => option.id.toString() === selectedPurchaseOptionId.toString(),
  );

  // license loading state & function
  const [licenseIdToDetails, setLicenseIdToDetails] = useState({});
  const [loadingLicense, setLoadingLicense] = useState(false);
  const loadLicense = async (licenseId) => {
    if (licenseIdToDetails[licenseId]) {
      return;
    }
    setLoadingLicense(true);
    try {
      const response = await axios.get(process.env.REACT_APP_TOPICS_API_LC_BASE_URL + `/licenses/${licenseId}`, {
        headers: {
          Authorization: `Bearer ${tokenAccessApi}`,
        },
      });
      setLicenseIdToDetails({ ...licenseIdToDetails, [licenseId]: response.data.license });
    } catch (err) {
      console.error("error while loading license", err);
      setLicenseIdToDetails({
        ...licenseIdToDetails,
        [licenseId]: { name: "Error", license_text: "Error loading license" },
      });
    } finally {
      setLoadingLicense(false);
    }
  };

  // purchasing state & function
  const [purchasing, setPurchasing] = useState(false);
  const [successfullyPurchased, setSuccessfullyPurchased] = useState(false);
  const submit = async () => {
    setPurchasing(true);
    try {
      const params = {
        assignmentId: parseInt(topic.id.toString()),
        networkIds: [parseInt(selectedNetwork)],
        purchaseOptionId: parseInt(selectedPurchaseOptionId),
      };

      const url = new URL(process.env.REACT_APP_TOPICS_API_LC_BASE_URL + `/${topic.id}/request`);

      await axios.post(url, params, {
        headers: {
          Authorization: `Bearer ${tokenAccessApi}`,
        },
      });
      setSuccessfullyPurchased(true);

      // update the feed so that it reflects the purchase you just made --
      // the content you just purchased should now have an active license and be unpurchaseable
      setFeedData((feedData) => {
        const newFeedData = { ...feedData };
        const purchasedItem = newFeedData.data.find((item) => item.id === topic.id);
        purchasedItem.activeLicenses = [
          {
            purchaseOptionId: selectedPurchaseOptionId,
            licenseId: selectedPurchaseOption.license_id,
            expirationTimestamp: Date.now() + selectedPurchaseOption.duration * 1000,
          },
        ];
        return newFeedData;
      });
    } catch (err) {
      console.error("error while submitting purchase", err);
      alert("error while purchasing");
      setPurchasing(false);
    }
  };

  if (loadingNetworkOptions) {
    return (
      <Modal isOpen onClickClose={close} id="purchaseModal">
        <div className="modal-title">Loading...</div>
      </Modal>
    );
  }

  if (!networkOptions.length) {
    return (
      <Modal isOpen onClickClose={close} id="purchaseModal">
        <div className="modal-title">No Networks Available</div>
        <p>
          You do not have any networks available to purchase this content. Please contact your organization's
          administrator for assistance.
        </p>
        <div className="form-row form-row-buttons">
          <button type="button" className="btn-primary" onClick={close}>
            Close
          </button>
        </div>
      </Modal>
    );
  }

  if (successfullyPurchased) {
    return (
      <Modal isOpen onClickClose={close} id="purchaseModal">
        <div className="modal-title">Purchase Complete</div>
        <p>
          <strong>{topic.title}</strong> has been successfully purchased.
        </p>
        <p>
          The content has been delivered to <strong>{networkNameMap[selectedNetwork]}</strong>'s Pilot feed.
        </p>
        <div className="form-row form-row-buttons">
          <button type="button" className="btn-primary" onClick={close}>
            Close
          </button>
        </div>
      </Modal>
    );
  }

  // a bit of a hack for showing the license easily
  if (viewLicense) {
    let title, body;
    if (loadingLicense) {
      title = "License";
      body = <p>Loading...</p>;
    } else if (licenseIdToDetails[selectedPurchaseOption.license_id]) {
      const license = licenseIdToDetails[selectedPurchaseOption.license_id];
      title = license.name;
      body = license.license_text.split("\n\n").map((paragraph, i) => <p key={i}>{paragraph}</p>);
    } else {
      loadLicense(selectedPurchaseOption.license_id);
      title = "License";
      body = <p>Loading...</p>;
    }

    return (
      <Modal isOpen onClickClose={() => setViewLicense(false)} id="purchaseModal">
        <div className="modal-title">{title}</div>
        <div className="license-text">{body}</div>
        <div className="form-row form-row-buttons">
          <button type="button" className="btn-secondary" onClick={() => setViewLicense(false)}>
            Close
          </button>
        </div>
      </Modal>
    );
  }

  return (
    <Modal isOpen onClickClose={() => (purchasing ? null : close())} id="purchaseModal">
      <div className="modal-title">Confirm Purchase</div>
      <p>
        By purchasing the content: <strong>{topic.title}</strong> you agree to the license fee and you confirm that you
        are authorized to purchase on behalf of your organization.
      </p>

      {topic.purchaseOptions.length > 1 && (
        <>
          <Select
            options={topic.purchaseOptions.map((option) => ({ value: option.id, label: option.name }))}
            name="Purchase Option"
            id="purchaseOption"
            onChange={(e) => setSelectedPurchaseOptionId(e.target.value)}
            value={selectedPurchaseOptionId}
          />
          {selectedPurchaseOption.description && (
            <>
              <p>{selectedPurchaseOption.description}</p>
            </>
          )}
        </>
      )}
      <p>
        <button type="button" className="btn-link" onClick={() => setViewLicense(true)}>
          View License
        </button>
      </p>
      <Select
        options={networkOptions}
        name="Network"
        id="networkSelect"
        onChange={(e) => setSelectedNetwork(e.target.value)}
        value={selectedNetwork}
      />
      <p>
        The bill payer for <strong>{networkNameMap[selectedNetwork]}</strong> will be charged{" "}
        <strong>{centsToDollarString(selectedPurchaseOption.price)}</strong> plus any associated taxes. The purchase
        will grant a license to use this content in your local market
        <strong>
          {selectedPurchaseOption.duration
            ? ` for ${getDurationString(selectedPurchaseOption.duration)}`
            : " indefinitely"}
        </strong>
        .
      </p>
      {/* just hardcoding this for now as a quick hack to get this out the door */}
      {CONNECT_PURCHASE_CONFIG[topic.org_id]?.extraPurchaseModalText && (
        <p>{CONNECT_PURCHASE_CONFIG[topic.org_id].extraPurchaseModalText}</p>
      )}
      <div className="form-row form-row-buttons">
        <button type="button" className="btn-secondary" onClick={close} disabled={purchasing}>
          Cancel
        </button>
        <button type="button" className="btn-primary" onClick={submit} disabled={purchasing}>
          {purchasing ? "Purchasing..." : "Purchase"}
        </button>
      </div>
    </Modal>
  );
};

export default PurchaseModal;
