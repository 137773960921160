import React from "react";
import { useParams } from "react-router-dom";
import { ReactComponent as NormallatakooBird } from "../../images/NormallatakooBird.svg";
import Header from "components/header/header";
import Sidebar from "components/sidebar/sidebar";
import FormHeader from "components/forms/formHeader";
import EditAssignmentForm from "components/forms/editAssignmentForm";
import { useUser } from "contexts/userContext";
import ResizablePanels from "components/resizablePanel";

const EditAssignment = () => {
  const { user, isMenuOpened, isSidebarOpened, hasError: error, isLoading: loading } = useUser();

  const { topicId } = useParams();
  const { assignmentId } = useParams();
  const { topicTitle } = useParams();
  // const { id } = useParams();

  const isMobile = window.innerWidth < 993;

  return (
    <>
      {loading && (
        <div className="initial-loading-container">
          <NormallatakooBird title="Latakoo logo" />
          <h1>We are loading your Manifest</h1>
          <div className="loading">
            <div className="loadingio-spinner-ellipsis">
              <div className="loader">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      )}

      {!loading && !error && user !== null && (
        <div
          className={isSidebarOpened ? `main sidebar-opened tags-${user.tags.length}` : `main tags-${user.tags.length}`}
          id={isMenuOpened ? "menu-opened" : ""}
        >
          <Header />
          {isMobile && (
            <>
              <Sidebar />
              <div className="forms">
                <FormHeader title={"Edit Assignment"} />
                <EditAssignmentForm
                  id={assignmentId}
                  topicId={topicId}
                  assignmentId={assignmentId}
                  slugJson={user.slugJson}
                  topicTitle={decodeURIComponent(topicTitle)}
                />
              </div>
            </>
          )}

          {!isMobile && (
            <ResizablePanels>
              <Sidebar />
              <div className="forms">
                <FormHeader title={"Edit Assignment"} />
                <EditAssignmentForm
                  topicId={topicId}
                  assignmentId={assignmentId}
                  slugJson={user.slugJson}
                  topicTitle={decodeURIComponent(topicTitle)}
                />
              </div>
            </ResizablePanels>
          )}
        </div>
      )}
    </>
  );
};

export default EditAssignment;
