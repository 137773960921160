import React from "react";
import { useParams } from "react-router-dom";
import Header from "components/header/header";
import Sidebar from "components/sidebar/sidebar";
import FormHeader from "components/forms/formHeader";
import AddAssignmentForm from "components/forms/addAssignmentForm";
import { ReactComponent as NormallatakooBird } from "../../images/NormallatakooBird.svg";
import { useUser } from "contexts/userContext";
import ResizablePanels from "components/resizablePanel";
import LoadingIndicator from "components/loadingIndicator";

const AddAssignment = () => {
  const { user, isMenuOpened, isSidebarOpened, hasError: error, isLoading: loading } = useUser();

  const { id } = useParams();
  const { title } = useParams();

  const isMobile = window.innerWidth < 993;

  return (
    <>
      {loading && (
        <div className="initial-loading-container">
          <NormallatakooBird title="Latakoo logo" />
          <h1>We are loading your Manifest</h1>
          <LoadingIndicator />
        </div>
      )}

      {!loading && !error && user !== null && (
        <div
          className={isSidebarOpened ? `main sidebar-opened tags-${user.tags.length}` : `main tags-${user.tags.length}`}
          id={isMenuOpened ? "menu-opened" : ""}
        >
          <Header />
          {isMobile && (
            <>
              <Sidebar />
              <div className="forms">
                <FormHeader title={"Add Assignment"} />
                <AddAssignmentForm id={id} title={decodeURIComponent(title)} slugJson={user.slugJson} />
              </div>
            </>
          )}

          {!isMobile && (
            <ResizablePanels>
              <Sidebar />
              <div className="forms">
                <FormHeader title={"Add Assignment"} />
                <AddAssignmentForm id={id} title={decodeURIComponent(title)} slugJson={user.slugJson} />
              </div>
            </ResizablePanels>
          )}
        </div>
      )}
    </>
  );
};

export default AddAssignment;
