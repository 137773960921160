import React from "react";
import ReactDOM from "react-dom";
import { MDBIcon } from "mdbreact";

const portalRoot = document.getElementById("portal-root");

const Modal = ({ children, isOpen, onClickClose, id }) => {
  if (!isOpen) {
    return null;
  }
  return ReactDOM.createPortal(
    <div className="ui-modal_overlay" id={id}>
      <div className="ui-modal">
        <button className="btn-close-modal" type="button" onClick={onClickClose}>
          <MDBIcon icon="times" />
        </button>
        {children}
      </div>
    </div>,
    portalRoot,
  );
};

export default Modal;
