// clean a dollar amount input string to match the regex \$\d+(\.\d?\d?)?
export const cleanDollarString = (priceString) => {
  const cleanString = priceString.replace(/[^\d.]/g, "");
  const splitString = cleanString.split(".");
  if (splitString.length === 0 || splitString[0] === "") {
    return "$0";
  } else if (splitString.length === 1) {
    return "$" + parseInt(splitString[0]);
  } else {
    return `$${parseInt(splitString[0]) || "0"}.${splitString[1].slice(0, 2)}`;
  }
};

// normalize a price input string to match the regex \$\d+(\.\d\d)?
export const normalizeDollarString = (priceString) => {
  const cleaned = cleanDollarString(priceString);
  const splitString = cleaned.split(".");
  if (splitString.length === 1) {
    return cleaned;
  } else if (!parseInt(splitString[1])) {
    // trim off the cents part if it's 0
    return splitString[0];
  } else {
    return cleaned + "0".repeat(2 - splitString[1].length);
  }
};

export const dollarStringToCents = (priceString) => {
  priceString = normalizeDollarString(priceString);
  if (!priceString.includes(".")) {
    priceString += ".00";
  }
  return parseInt(priceString.replace(/[^\d]/g, ""));
};

export const centsToDollarString = (cents) => {
  if (parseInt(cents) === 0) {
    return normalizeDollarString("$0");
  }
  const centsString = cents.toString();
  const centsPart = centsString.slice(-2);
  const dollarsPart = centsString.slice(0, -2);
  return normalizeDollarString(`$${dollarsPart}.${centsPart}`);
};
