import React from "react";

const FormTags = ({ tags, searchParam }) => {
  return (
    <div className="form-tags">
      <ul>
        {tags.map((tag, i) => (
          <li key={i}>
            {searchParam === tag && (
              <input type="checkbox" name="subTags[]" value={tag} id={`search_tag_${i}`} defaultChecked />
            )}
            {searchParam !== tag && <input type="checkbox" name="subTags[]" value={tag} id={`search_tag_${i}`} />}
            <label htmlFor={tag}>{tag}</label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FormTags;
