import React from "react";
import axios from "axios";
import Modal from "pages/manifest/modal";
import { MDBIcon } from "mdbreact";

import { useUser } from "contexts/userContext";

const PublishModal = ({ onClickClose, card, published, setPublished, publishing, setPublishing }) => {
  const { tokenAccessApi } = useUser();

  const publish = () => {
    setPublishing(true);

    const visibilityObject = {
      visibility: published ? "private" : "public",
    };

    axios
      .patch(`${process.env.REACT_APP_TOPICS_API_LC_BASE_URL}/${card.id}/visibility`, visibilityObject, {
        headers: {
          Authorization: `Bearer ${tokenAccessApi}`,
        },
      })
      .then(function (response) {
        setPublished(!published);
        setPublishing(false);
        setTimeout(onClickClose, 3000);
      })
      .catch(function (error) {
        setPublishing(false);
        console.log(error);
      });
  };

  return (
    <Modal isOpen onClickClose={onClickClose} id={"rssModal"}>
      <div className="modal-title">Publish content</div>
      <p>
        {published
          ? "If you unpublish this file: It will be unavailable to ALL latakoo users, but the content and metadata will not be lost. You can can make edits, if needed, and republish."
          : "Publishing makes content under this Topic available for use by ALL latakoo users."}
      </p>

      <div className="form-row form-row-buttons">
        <button type="button" className="btn-secondary" onClick={onClickClose}>
          Close
        </button>
        <button type="button" className={published ? "btn-published" : "btn-publish"} onClick={() => publish()}>
          {publishing && <MDBIcon icon="spinner" />}
          {!publishing && <span>{published ? "unpublish" : "publish"} </span>}
        </button>
      </div>
    </Modal>
  );
};

export default PublishModal;
