import React from "react";
import Header from "components/header/header";
import Sidebar from "components/sidebar/sidebar";
import Feed from "components/feed/feed";
import { ReactComponent as NormallatakooBird } from "../../images/NormallatakooBird.svg";

import { FeedContextProvider } from "contexts/feedContext";

import { useUser } from "contexts/userContext";
import ResizablePanels from "components/resizablePanel";
import LoadingIndicator from "components/loadingIndicator";

const Manifest = () => {
  const {
    hasError: error,
    initialLoad,
    isLoadingTrees,
    isLoadingUser,
    isMenuOpened,
    isSidebarOpened,
    orgsTree,
    user,
  } = useUser();

  const loading = initialLoad || isLoadingTrees || !orgsTree || isLoadingUser || !user;
  const isMobile = window.innerWidth < 993;

  return (
    <>
      {loading && (
        <div className="initial-loading-container">
          <NormallatakooBird title="Latakoo logo" />
          {/* <h1>We are loading your Manifest</h1> */}
          <h1>
            We are loading latakoo Connect
            <br />
            <br />
            You are leaving your company's internal latakoo workflow, and will have access to{" "}
            <strong>third-party content</strong>. Follow your organization's guidelines on usage.
          </h1>
          <LoadingIndicator />
        </div>
      )}

      {!loading && error && (
        <div className="error-loading-manifest">
          <p>You do not have permissions to access Manifest.</p>
          <p>
            Please contact your latakoo Administrator or <a href="mailto:support@latakoo.com">support@latakoo.com</a> to
            request permissions.
          </p>
        </div>
      )}

      {!loading && !error && (
        <div
          className={isSidebarOpened ? `main sidebar-opened tags-${user.tags.length}` : `main tags-${user.tags.length}`}
          id={isMenuOpened ? "menu-opened" : ""}
        >
          <Header />
          {isMobile && (
            <>
              <Sidebar />
              <Feed />
            </>
          )}

          {!isMobile && (
            <ResizablePanels>
              <Sidebar />
              <Feed />
            </ResizablePanels>
          )}
        </div>
      )}
    </>
  );
};

export default Manifest;
