import React from "react";
import Modal from "pages/manifest/modal";
import { MDBIcon } from "mdbreact";

const LocationModal = ({ onClickClose, mapLocation }) => {
  return (
    <Modal isOpen onClickClose={onClickClose} id={"mapModal"}>
      <div className="modal-title">
        <MDBIcon icon="map-marker-alt" /> Location
      </div>
      <iframe
        width="100%"
        height="400"
        frameBorder="0"
        title="Location"
        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAseIjfHwEFrDmM8VDjDvkFp1OPZ64kwVI&q=${mapLocation}&zoom=8`}
      ></iframe>
    </Modal>
  );
};

export default LocationModal;
