import React, { useEffect, useState } from "react";
import Modal from "pages/manifest/modal";
import useApi from "components/utils/useApi";
import Cookies from "universal-cookie";
import axios from "axios";
import { MDBIcon } from "mdbreact";
import { useUser } from "contexts/userContext";

const TimeZoneModal = ({ onClickClose }) => {
  const { user } = useUser();
  const cookies = new Cookies();
  let timeZone =
    cookies.get("tzCookie") !== undefined && cookies.get("tzCookie") !== ""
      ? cookies.get("tzCookie")
      : Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [timeZoneCookie, setTimeZoneCookie] = useState(timeZone);
  // let newCookieValue = tzCookie

  const [load, loadInfo] = useApi({
    url: `/time-zone`,
  });

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const postTimezone = React.useCallback(async () => {
    const url = new URL(`${process.env.REACT_APP_API_BASE_URL}/time-zone/${timeZoneCookie}`);
    url.searchParams.set("ownerid", user.id);

    try {
      await axios.post(url);
    } catch (error) {
      console.error(error);
    }
  }, [timeZoneCookie, user]);

  function onChange(e) {
    setTimeZoneCookie(e.target.value);
    // newCookieValue = e.target.value
  }

  function clickTz(e) {
    e.preventDefault();
    cookies.set("tzCookie", timeZoneCookie, { path: "/" });
    postTimezone();
    window.location.reload();
  }

  return (
    <Modal isOpen onClickClose={onClickClose} id={"timeZoneModal"}>
      <div className="modal-title">
        <MDBIcon far icon="clock" /> Your Timezone
      </div>
      <form action={``} method="get" id="Timezone">
        <div className="form-row">
          <label htmlFor="tz">Please select your timezone below</label>
          <select className="form-control" name="tz" id="tz" value={timeZoneCookie} onChange={onChange}>
            {loadInfo.data &&
              loadInfo.data.map((item, i) => (
                <option key={i} value={item.value}>
                  {item.name}
                </option>
              ))}
          </select>
        </div>
        <div className="form-row form-row-buttons">
          <button type="button" className="btn-secondary" onClick={onClickClose}>
            Close
          </button>
          <button type="submit" className="btn-primary" onClick={(e) => clickTz(e)}>
            Save
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default TimeZoneModal;
