const AssetTypes = {
  AUDIO: "audio",
  FOLDER: "folder",
  GROUP: "group",
  HLS_STREAMING: "hls_streaming",
  IMAGE: "image",
  OTHER: "other",
  STITCH: "stitch",
  STREAMING: "streaming",
  VIDEO: "video",
};

export default AssetTypes;
