import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import DeleteAssignmentModal from "components/modal/deleteAssignmentModal";
import LocationModal from "components/modal/locationModal";
import Video from "components/feed/assetType/video";
import Transcription from "components/feed/controllers/transcription";
import VideoGeoLocation from "components/feed/controllers/videoGeoLocation";
import { MDBIcon } from "mdbreact";
import $ from "jquery";
import { useUser } from "contexts/userContext";
import { FeedSourceType, useFeed } from "contexts/feedContext";
import CardRequest from "../controllers/cardRequest";
import AssignmentHeaderThumbnail from "./assignmentHeaderThumbnail";

const Assignment = ({ topic, assignment, isOwner }) => {
  const { user } = useUser();

  const { feedConfig } = useFeed();

  let path = window.location.pathname.toLocaleLowerCase();
  let openedAssignment;
  if (path.indexOf("/topic/") > -1) {
    openedAssignment = path.split("/topic/")[1].split("/")[1];
  } else if (path.indexOf("/connect-topic/") > -1) {
    openedAssignment = path.split("/connect-topic/")[1].split("/")[1];
  }

  const [isOpened, setOpened] = useState(false);

  function onClick(id) {
    setOpened(!isOpened);
  }

  useEffect(() => {
    if (assignment.id === parseInt(openedAssignment)) {
      setOpened(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [following, setFollowing] = useState(assignment.isFollowing);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showRequestDropdown, setShowRequestDropdown] = useState(false);

  const clickFollow = (assignmentId) => {
    setButtonLoading(true);

    const mode = following ? "10" : "8";

    axios
      .post(
        `${process.env.REACT_APP_GATEWAY_API_BASE_URL}?mode=${mode}&assignmentid=${topic.id}&child_id=${assignmentId}&org_id=${user.organization_id}&userid=${user.id}&tz=${user.tz}`,
      )
      .then(function (response) {
        setButtonLoading(false);
        setFollowing(following ? false : true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [showLocation, setShowLocation] = useState(false);
  const [mapLocation, setMapLocation] = useState("");

  function showMapModal(location) {
    setMapLocation(location);
    setShowLocation(!showLocation);
  }

  const [assignmentDelete, setAssignmentDelete] = useState(false);

  const createMarkup = (html) => {
    return { __html: html };
  };

  const titleHtml = assignment.title.replace(/_/g, "_<span></span>");

  const clickOpenArea = (cardId) => {
    $(`.${cardId}`).find(".card-toggle-btn").trigger("click");
  };

  const isConnect =
    feedConfig.source === FeedSourceType.CONNECT_SINGLE_TOPIC ||
    feedConfig.source === FeedSourceType.CONNECT_ORGANIZATION;

  return (
    <li
      className={isOpened ? `card assignmentId-${assignment.id} card-opened` : `card assignmentId-${assignment.id}`}
      key={assignment.id}
    >
      <div className="card-header">
        <div className="card-header_infos">
          <div className="card_header-thumbnail">
            {assignment.cntVideos > 0 && (
              <div className="card_header-thumbnail">
                <AssignmentHeaderThumbnail card={assignment} clickOpenArea={clickOpenArea} />
              </div>
            )}
          </div>

          <div className="card-header_title">
            <h2>
              <a
                href={`/${isConnect ? "connect-" : ""}topic/${topic.id}/${assignment.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <em dangerouslySetInnerHTML={createMarkup(titleHtml)}></em> <MDBIcon icon="external-link-alt" />
              </a>
            </h2>
            {!isConnect && (
              <a
                href={`https://manifestv3.latakoo.com/api/tmpLog/?topicid=&assignmentid=${assignment.id}`}
                className="download_logs-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download logs <MDBIcon icon="arrow-down" />
              </a>
            )}
          </div>
        </div>
        {window.innerWidth > 1080 && (
          <div className="card_open-area-middle" onClick={() => clickOpenArea(`assignmentId-${assignment.id}`)}>
            &nbsp;
          </div>
        )}
        <div className="card-header_controls">
          <div className="card-request-container">
            {(!topic.purchaseOptions || topic.purchaseOptions.length === 0) && (
              <button
                type="button"
                className="card-request-btn"
                title="request"
                onClick={() => setShowRequestDropdown(!showRequestDropdown)}
              >
                {/* <MDBIcon icon="clone" /> */} <span>request</span>
              </button>
            )}
            {showRequestDropdown && (
              <CardRequest card={assignment} parentId={topic.id} setShowRequestDropdown={setShowRequestDropdown} />
            )}
          </div>

          {isOwner && (
            <Link
              className="card-edit-btn"
              to={`/editassignment/${topic.id}/${assignment.id}/${encodeURIComponent(topic.title)}`}
            >
              <MDBIcon icon="pencil-alt" />
            </Link>
          )}
          {isOwner && (
            <button
              type="button"
              className="card-delete-btn"
              title="delete"
              onClick={() => setAssignmentDelete(!assignmentDelete)}
            >
              <MDBIcon far icon="trash-alt" />
            </button>
          )}
          {assignmentDelete && (
            <DeleteAssignmentModal
              onClickClose={() => setAssignmentDelete(false)}
              id={assignment.id}
              userId={user.id}
              orgId={user.organization_id}
              topicId={topic.id}
            />
          )}
          <button
            type="button"
            className={following ? `btn-follow unfollow` : `btn-follow`}
            id={following ? `ajax-target_uf${assignment.id}` : `ajax-target_${assignment.id}`}
            onClick={() => clickFollow(assignment.id)}
          >
            {buttonLoading && <MDBIcon icon="spinner" />}
            {!buttonLoading && <MDBIcon icon="eye" />}
          </button>
          <button type="button" className="card-toggle-btn" onClick={() => onClick(`${assignment.id}`)}>
            <MDBIcon icon="chevron-down" />
          </button>
        </div>
      </div>
      <div className="card_open-area-bottom" onClick={() => clickOpenArea(`assignmentId-${assignment.id}`)}></div>
      <div className="card-body">
        <div className="assignmnet-card-body_info">
          <div className="assignmnet-card-body_info-metadata">
            <dl>
              <dt>Created by</dt>
              <dd>
                <a href={`mailto:${assignment.u_email}`} title={assignment.u_email}>
                  {assignment.u_name}
                </a>
              </dd>
            </dl>
            <dl>
              <dt>Location</dt>
              <dd>
                <span className="link-text" onClick={() => showMapModal(assignment.location)}>
                  {assignment.location}
                </span>
              </dd>
            </dl>
            <dl>
              <dt>Date Entered</dt>
              <dd>{assignment.dateentered}</dd>
            </dl>
            <dl>
              <dt>Date Updated</dt>
              <dd>{assignment.datechanged}</dd>
            </dl>
            {/* <dl className="networks">
							<dt>Networks</dt>
							<dd>
								{assignment.networks.length > 0 &&
									assignment.networks.map((network, i) => <span key={i}>{network.network_name}</span>)
								}
							</dd>
						</dl> */}
            {assignment.FormField &&
              // eslint-disable-next-line
              assignment.FormField.filter((field) => field.value).map((field, i) => {
                return (
                  <dl key={i++} className={field.type}>
                    <dt>{field.label.replace(/&amp;/g, "&")}</dt>
                    <dd className={field.value === null ? "empty" : ""}>{field.value}</dd>
                  </dl>
                );
              })}
            <dl>
              <dt>Assigned to</dt>
              <dd>
                {assignment.assignedto.length > 0 &&
                  assignment.assignedto.map((assignedto, i) => (
                    <a key={i} href={`mailto:${assignedto.email}`} title={assignedto.email}>
                      {assignedto.name}
                    </a>
                  ))}
                {assignment.assignedto.length <= 0 && <span>No Assignees</span>}
              </dd>
            </dl>
            {assignment.cntVideos > 0 &&
              assignment.videos.map((video, i) => {
                return video.remote_ip !== null ? <VideoGeoLocation video={video} key={i} /> : false;
              })}
          </div>
          <div className="assignmnet-card-body_info-videos">
            {assignment.cntVideos > 0 &&
              assignment.videos.map((video, i) => (
                <Video
                  video={video}
                  key={i}
                  cardId={topic.id}
                  assignmentId={assignment.id}
                  userId={user.id}
                  orgId={user.organization_id}
                  assetTypes={topic.assignment_video_types}
                />
              ))}
            {assignment.cntVideos === 0 && <div className="assignment_video-container">No Videos</div>}
          </div>
          {assignment.cntVideos > 0 &&
            assignment.videos.map((video, i) => (
              <div key={i} className="transcription-container">
                {video.hasTranscription && (
                  <Transcription userId={video.userid} videoId={video.videoid} key={i} aId={assignment.id} />
                )}
              </div>
            ))}
        </div>
      </div>

      {showLocation && <LocationModal onClickClose={() => setShowLocation(false)} mapLocation={mapLocation} />}
    </li>
  );
};

export default Assignment;
