import React, { useEffect, useState } from "react";
import { MDBInput } from "mdbreact";
import {
  cleanDollarString,
  normalizeDollarString,
  dollarStringToCents,
  centsToDollarString,
} from "components/utils/priceString";

const DollarInput = ({ label, id, name, onUpdateValue, value }) => {
  const [internalValue, setInternalValue] = useState(value ? centsToDollarString(value) : "Free");
  useEffect(() => {
    onUpdateValue(internalValue);
  }, [internalValue]);
  const finalValue = value ?? internalValue;
  return (
    <MDBInput
      type="text"
      label={label}
      id={id}
      name={name}
      onChange={(e) => setInternalValue(cleanDollarString(e.target.value))}
      onFocus={() => (dollarStringToCents(internalValue) < 1 ? setInternalValue("$") : null)}
      onBlur={() =>
        dollarStringToCents(internalValue) < 1
          ? setInternalValue("Free")
          : setInternalValue(normalizeDollarString(internalValue))
      }
      value={finalValue}
    />
  );
};

export default DollarInput;
