import React, { useState, useEffect } from "react";
// import {
//   BrowserRouter as Router,
//   Link
// } from 'react-router-dom';
import { ReactComponent as LatakooLogo } from "../../images/LatakooLogo.svg";
import { MDBIcon } from "mdbreact";
import { useUser } from "contexts/userContext";
import { useMobileWebview } from "contexts/mobileWebviewContext";
import FontSizeButton from "components/settings/fontResizeButton";

const Header = () => {
  const { user, openMenu, openSidebar } = useUser();
  const { isMobileWebview } = useMobileWebview();

  const isLatakooAdmin = user?.latakoo_role === "admin";

  let userImageLink = "https://latakoo.com/hif/images/test-bnner.jpg?" + Math.random() + "";
  let time_start, end_time;
  const [speed, setSpeed] = useState("");

  // The size in bytes
  let downloadSize = 4610000;
  let downloadImgSrc = new Image();

  time_start = new Date().getTime();
  downloadImgSrc.src = userImageLink;

  function displaySpeed() {
    let timeDuration = (end_time - time_start) / 1000;
    let loadedBits = downloadSize * 8;
    let bps = (loadedBits / timeDuration).toFixed(2);
    let speedInKbps = (bps / 1024).toFixed(2);
    let speedInMbps = (speedInKbps / 1024).toFixed(2);
    setSpeed(speedInMbps);
  }

  useEffect(() => {
    downloadImgSrc.onload = function () {
      end_time = new Date().getTime();
      displaySpeed();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function goBack(e) {
    e.preventDefault();
    window.history.back();
  }

  if (isMobileWebview) {
    return null;
  }

  return (
    <header className="header">
      <button className="btn-go-back" type="button" onClick={(e) => goBack(e)}>
        <MDBIcon icon="arrow-left" />
      </button>
      <div className="header-container">
        <div className="header-hamburguer">
          <button className="hamburger hamburger--slider" type="button" onClick={openSidebar}>
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
        </div>
        <div className="header-logo" onClick={openMenu}>
          <LatakooLogo title="Latakoo logo" />
        </div>
        <div className="header-navigation">
          <ul>
            <li>
              <a href="//home.latakoo.com/en/">Home</a>
            </li>
            <li>
              <a href="//pilot.latakoo.com/">Pilot</a>
            </li>
            <li>
              <a href="//latakoo.com/-/manage/">Manage</a>
            </li>
            <li>
              <a href="//latakoo.com/-/download/">Get the app</a>
            </li>
            <li>
              <a href="//latakoo.com/-/support/">Support</a>
            </li>
            {isLatakooAdmin && (
              <li>
                <a href="//latakoo.com/chiefbird/">Admin</a>
              </li>
            )}
          </ul>
          <div className="header-user">
            <span>{user.name}</span>
            <div className="header-user_image">
              <img
                src={user.thumbnail ? user.thumbnail : "//latakoo.com/hif/images/latakoowhite.png"}
                alt={user.name}
              />
            </div>
            <div className="header-user_drop">
              <div className="header-user_drop-speed">
                <div>
                  Internet Speed: <strong>{speed} Mbps</strong>
                </div>
                {/* <div>Geo Location: xxxxxx</div>
								<div>ISP: xxxxxx</div> */}
              </div>
              <FontSizeButton />
              <a href="//latakoo.com/settings/">
                <MDBIcon icon="cog" /> Settings
              </a>
              <a href="//latakoo.com/-/support/">
                <MDBIcon icon="wifi" /> Help
              </a>
              <a href="/">
                <MDBIcon icon="sync-alt" /> Synch Org
              </a>
              <a href="https://api.pilot.latakoo.com/api/signout.php">
                <MDBIcon icon="power-off" /> Log out
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
