import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { Multiselect } from "multiselect-react-dropdown";
import * as R from "ramda";

import { useUser } from "contexts/userContext";

const allowMoveCopy = (network) => network && (network.move_copy === undefined || network.move_copy === 1);

const CardRequest = ({ card, parentId, setShowRequestDropdown }) => {
  const { user, tokenAccessApi } = useUser();

  const [requestedNetworksSet, setRequestedNetworksSet] = useState(new Set(card.networks));
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);

  const placeholder = useMemo(() => {
    const countString = requestedNetworksSet.size > 0 ? `${requestedNetworksSet.size.toString()} requested` : "Request";
    return countString;
  }, [requestedNetworksSet]);

  useEffect(() => {
    const networkOptions = [];
    const networksMap = new Map(
      user.network_list.filter(allowMoveCopy).map((network) => R.pair(network.company_id.toString(), network)),
    );

    if (networksMap.has(user.default_network)) {
      const defaultNetwork = networksMap.get(user.default_network);
      networkOptions.push({
        group: "Default Networks",
        value: defaultNetwork.company_id,
        name: defaultNetwork.company_name,
        id: defaultNetwork.company_id,
        selected: requestedNetworksSet.has(parseInt(user.default_network, 10)),
        className: "",
      });
    }

    networkOptions.push(
      ...Array.from(requestedNetworksSet)
        .filter(
          (requestedNetworkId) =>
            networksMap.has(requestedNetworkId.toString()) && requestedNetworkId.toString() !== user.default_network,
        )
        .map((requestedNetworkId) => networksMap.get(requestedNetworkId.toString()))
        .map((requestedNetwork) => ({
          group: "Requested Networks",
          value: requestedNetwork.company_id,
          name: requestedNetwork.company_name,
          id: requestedNetwork.company_id,
          selected: true,
          className: "selected",
        })),
    );

    // console.log(...Array.from(networksMap.values()));

    networkOptions.push(
      ...Array.from(networksMap.values())
        .filter((network) => network.company_name !== null)
        .filter(
          (network) => networkOptions.find((networkOption) => networkOption.id === network.company_id) === undefined,
        )
        .map((network) => ({
          group: "Available Networks",
          value: network.company_id,
          name: network.company_name,
          id: network.company_id,
          selected: false,
          className: "not-selected",
        })),
    );

    setOptions(networkOptions);
    setSelected(networkOptions.filter(({ selected }) => selected));
  }, [requestedNetworksSet, user.network_list, user.default_network]);

  const onChangeRequestedNetworks = (selectedNetworks) => {
    const newRequestedNetworksSet = new Set(selectedNetworks.map(({ id }) => id));
    setRequestedNetworksSet(newRequestedNetworksSet);
  };

  const onSendRequest = () => {
    updateRequestedNetworks();
    const newSelectedOptions = options.filter((item) => requestedNetworksSet.has(item.id));
    setSelected(newSelectedOptions);
    setShowRequestDropdown(false);
  };
  const onCancelRequest = () => {
    setShowRequestDropdown(false);
  };

  const updateRequestedNetworks = async () => {
    const topicId = !parentId ? card.id : parentId;
    const childId = !parentId ? undefined : card.id;

    const params = {
      assignmentId: topicId,
      assignmentChildId: childId,
      networkIds: [...requestedNetworksSet],
    };

    const url = new URL(process.env.REACT_APP_TOPICS_API_LC_BASE_URL + `/${topicId}/request`);

    try {
      await axios.post(url, params, {
        headers: {
          Authorization: `Bearer ${tokenAccessApi}`,
        },
      });
    } catch (error) {
      console.warn(error);
    }
  };

  return (
    <div className="card_request-component">
      <Multiselect
        placeholder={placeholder}
        options={options}
        groupBy="group"
        displayValue="name"
        showCheckbox={true}
        closeIcon="cancel"
        selectedValues={selected}
        onSelect={onChangeRequestedNetworks}
        onRemove={onChangeRequestedNetworks}
        optionClassName="className"
      />
      <div className="card_request-component_buttons">
        <button type="button" className="btn-secondary" onClick={onCancelRequest}>
          Cancel
        </button>
        <button type="button" className="btn-secondary" onClick={onSendRequest}>
          Request
        </button>
      </div>
    </div>
  );
};

export default CardRequest;
