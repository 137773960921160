import React, { useState, useEffect } from "react";

const TagsList = ({ tags, className, index, onChangeAlt, preSelectedTags, id }) => {
  const [selectedTags, setSelectedTags] = useState(preSelectedTags !== undefined ? preSelectedTags : []);

  function selectTags(ev) {
    if (selectedTags.indexOf(ev.target.value) > -1) {
      function checkST(st) {
        return st !== ev.target.value;
      }
      setSelectedTags(selectedTags.filter(checkST));
    } else {
      setSelectedTags([...selectedTags, ev.target.value]);
    }
  }

  useEffect(() => {
    if (selectedTags.length !== 0) {
      onChangeAlt(index, selectedTags, id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTags]);

  const removeAmpersand = (tag) => {
    return tag.replace("&amp;", "&");
  };

  return (
    <div className={`form-row tags-row ${className}`}>
      <div className="form-tags">
        <div className="label-title">Tags</div>
        <ul>
          {tags.map((tag, i) => {
            if (preSelectedTags !== undefined && preSelectedTags.length > 0 && preSelectedTags.includes(tag)) {
              return (
                <li key={i}>
                  <input type="checkbox" value={tag} id={tag} onClick={selectTags} index={index} defaultChecked />
                  <label htmlFor={tag}>{removeAmpersand(tag)}</label>
                </li>
              );
            } else {
              return (
                <li key={i}>
                  <input type="checkbox" value={tag} id={tag} onClick={selectTags} index={index} />
                  <label htmlFor={tag}>{removeAmpersand(tag)}</label>
                </li>
              );
            }
          })}
        </ul>
      </div>
    </div>
  );
};

export default TagsList;
