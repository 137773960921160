import React, { useEffect, useState } from "react";
import { MDBInput } from "mdbreact";

const HourDurationInput = ({ label, id, name, onUpdateValue, emptyValue, value }) => {
  const [internalValue, setInternalValue] = useState(value ?? 0);
  const [focused, setFocused] = useState(false);
  useEffect(() => {
    onUpdateValue(internalValue);
  }, [internalValue]);

  const updateValue = (str) => {
    const hours = parseInt(str.replace(/[^\d]/g, ""));
    if (!hours) {
      setInternalValue(0);
    }
    setInternalValue(hours);
  };

  const formatValue = (value) => {
    if (focused) {
      return value ? value : "";
    } else {
      return value ? `${value} hours` : emptyValue || "0 hours";
    }
  };

  const finalValue = value ?? internalValue;

  return (
    <MDBInput
      type="text"
      label={label}
      id={id}
      name={name}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      onChange={(e) => updateValue(e.target.value)}
      value={formatValue(finalValue)}
    />
  );
};

export default HourDurationInput;
