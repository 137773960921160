import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";

let autoComplete;

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

function handleScriptLoad(updateQuery, autoCompleteRef) {
  autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current, {
    types: [],
    componentRestrictions: { country: [] },
  });
  // autoComplete.setFields(["address_components", "formatted_address"]);
  autoComplete.addListener("place_changed", () => handlePlaceSelect(updateQuery));
}

async function handlePlaceSelect(updateQuery) {
  const addressObject = autoComplete.getPlace();
  const query =
    addressObject.formatted_address.indexOf(addressObject.name) > -1
      ? addressObject.formatted_address
      : addressObject.name + " " + addressObject.formatted_address;
  // const query = addressObject.formatted_address;
  updateQuery(query);
  onCL(query, fieldId, valueIndex);
}

let fieldId = "";
let valueIndex = "";
let onCL = "";

function SearchLocationInput({ order, inform, id, required, onChangeLocation, index, initValue }) {
  fieldId = id;
  valueIndex = index;
  onCL = onChangeLocation;
  const [query, setQuery] = useState(initValue === undefined ? "" : initValue);
  const autoCompleteRef = useRef(null);
  const [labelClass, setLabelClass] = useState(initValue === undefined ? "" : "active");

  function onFocus() {
    setLabelClass("active");
  }

  function onBlur(ev) {
    onChangeLocation(ev.target.value, ev.target.id, index);
  }

  function handleKeyPress(ev) {
    if (ev.keyCode === 13) {
      ev.preventDefault();
    }
  }

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyAseIjfHwEFrDmM8VDjDvkFp1OPZ64kwVI&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef),
    );
    autoCompleteRef.current.addEventListener("keypress", (e) => handleKeyPress(e));

    // return () => {
    //     autoCompleteRef.current.removeEventListener('keypress', (e) => handleKeyPress(e));
    // }
  }, []);

  return (
    <div className={`${order} ${inform}`}>
      <div className="md-form">
        <input
          ref={autoCompleteRef}
          onChange={(event) => setQuery(event.target.value)}
          onBlur={(ev) => onBlur(ev)}
          onFocus={onFocus}
          placeholder=""
          value={query}
          required={required}
          data-test="input"
          name="Location"
          index={index}
          type="text"
          className="form-control pepe"
          id={id}
          aria-disabled="false"
        />
        <label className={labelClass} htmlFor={id} data-error="" data-success="" id="" aria-labelledby="">
          Location
        </label>
      </div>
    </div>
  );
}

export default SearchLocationInput;
