import React, { useState, useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import Modal from "pages/manifest/modal";
import FormTags from "components/feed/controllers/formTags";
import "react-datepicker/dist/react-datepicker.css";
import { MDBIcon } from "mdbreact";

import { useUser } from "contexts/userContext";

const SearchModal = ({ onClickClose, searchParam }) => {
  const { user } = useUser();
  const { tags } = user;

  let path = window.location.pathname.split("/assignment/");
  let org = path[1] !== undefined ? path[1] : "";
  let isLocation = "";
  let organizations = user.org_tree.children;

  function getOrgName(id) {
    organizations.map((org) => {
      if (org.id == id) {
        isLocation = org.name;
      } else {
        org.children.map((chlOrg) => {
          if (chlOrg.id == id) {
            isLocation = chlOrg.name;
          }
          return true;
        });
      }
      return true;
    });
    return isLocation;
  }

  if (org.indexOf("user") > -1) {
    isLocation = user.curr_organization_name;
  } else if (org.indexOf("top-organization") > -1) {
    isLocation = user.top_organization_name;
  } else if (org.indexOf("organization/") > -1) {
    isLocation = getOrgName(org.split("/")[1]);
  } else if (org.indexOf("organization") > -1) {
    isLocation = user.curr_organization_name;
  } else {
    isLocation = user.curr_organization_name;
  }

  let searchTag =
    window.location.search.indexOf("?tag=") > -1 ? decodeURI(window.location.search).split("?tag=")[1] : ``;

  const searchKeyword = useRef(null);
  useEffect(() => {
    searchKeyword.current.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [lastDay, setLastDay] = useState(true);
  const [expiredItemsIncluded, setExpiredItemsIncluded] = useState(false);
  const [gcIncluded, setGcIncluded] = useState(true);
  const [tagsIncluded, setTagsIncluded] = useState(false);
  const [deletedItemsIncluded, setDeletedItemsIncluded] = useState(false);

  function onClickLastDay() {
    setLastDay(!lastDay);
  }

  function onClickExIncluded() {
    setExpiredItemsIncluded(!expiredItemsIncluded);
  }

  function onClickGcIncluded() {
    setGcIncluded(!gcIncluded);
  }

  function onClickDelIncluded() {
    setDeletedItemsIncluded(!deletedItemsIncluded);
  }

  function onClickTagsIncluded() {
    setTagsIncluded(!tagsIncluded);
  }

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const url = window.location.href;

  return (
    <Modal isOpen onClickClose={onClickClose} id={"searchModal"}>
      <form action={url} method="get" id="my_form" className="search-form-modal">
        <div className="modal-title" id="getSessionStorage">
          <MDBIcon icon="search" />
          <input type="text" name="Keyword" placeholder="Search" ref={searchKeyword} />
        </div>

        <input type="hidden" name="hiddenValue" id="hiddenValue" value="Search" />
        <input type="hidden" name="modal_search" id="modal_search" value="1" />

        <p className="search-within">
          Searching within <strong>{isLocation.replace("Spectrum ", "")}</strong>
          {searchTag && (
            <span>
              {" "}
              &amp; <strong>{searchTag}</strong>
            </span>
          )}
        </p>

        <div className="form-row checkbox-label">
          <input
            type="checkbox"
            name="last24hours"
            id="last24hours"
            value={lastDay}
            defaultChecked
            onClick={onClickLastDay}
          />
          <label htmlFor="last24hours">Include unexpired Topics and Assignments</label>
        </div>

        <div className="form-row checkbox-label">
          <input
            type="checkbox"
            name="expired-items"
            id="expired-items"
            value={expiredItemsIncluded}
            onClick={onClickExIncluded}
          />
          <label htmlFor="expired-items">Include expired Topics and Assignments</label>
        </div>

        {expiredItemsIncluded && (
          <div>
            <p>Please select the date range of the assignments. This uses "Creation Date" as the filter parameter.</p>
            <div className="form-row form-row_2cols form-date-picker">
              <div>
                <label>From</label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  name="fromDate"
                  value={startDate}
                  isClearable
                  placeholderText="Select 'from' day"
                  dateFormat="yyyy/MM/dd"
                />
                <span className="">
                  <MDBIcon icon="calendar-alt" />
                </span>
              </div>
              <div>
                <label>Until</label>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  name="untilDate"
                  value={endDate}
                  isClearable
                  placeholderText="Select 'until' day"
                  dateFormat="yyyy/MM/dd"
                />
                <span className="">
                  <MDBIcon icon="calendar-alt" />
                </span>
              </div>
            </div>

            <div className="form-row checkbox-label">
              <input
                type="checkbox"
                name="deleted-items"
                id="deleted-items"
                value={deletedItemsIncluded}
                onClick={onClickDelIncluded}
              />
              <label htmlFor="deleted-items">Include deleted Topics and Assignments</label>
            </div>
          </div>
        )}

        <div className="form-row checkbox-label">
          <input
            type="checkbox"
            name="include-gc"
            id="include-gc"
            value={gcIncluded}
            defaultChecked
            onClick={onClickGcIncluded}
          />
          <label htmlFor="include-gc">Include Unassigned Assets</label>
        </div>

        <div className="form-row checkbox-label">
          <input
            type="checkbox"
            name="include-tags"
            id="include-tags"
            value={tagsIncluded}
            onClick={onClickTagsIncluded}
          />
          <label htmlFor="include-tags">Include tags</label>
        </div>

        {tagsIncluded && (
          <div>
            <div className="md-form radio-btn-container">
              <input id="And" type="radio" value="and" name="searchjoin" />
              <label htmlFor="And">All</label>
              <input id="Or" type="radio" value="or" name="searchjoin" defaultChecked />
              <label htmlFor="Or">Any</label>
            </div>
            <p>Tags</p>
            <div className="form-row">
              <div>
                <input type="hidden" name="subTags[]" value="9981" />
                <FormTags tags={tags} searchParam={searchParam} />
              </div>
            </div>
          </div>
        )}

        <div className="form-row form-row-buttons">
          <button type="button" className="btn-secondary" onClick={onClickClose}>
            Cancel
          </button>
          <button type="submit" className="btn-primary">
            Search
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default SearchModal;
