import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { MDBIcon } from "mdbreact";

import { FeedSourceType, useFeed } from "../../contexts/feedContext";
import SidebarMenu from "components/sidebar/sidebarMenu";
import { useUser } from "contexts/userContext";
import { useMobileWebview } from "contexts/mobileWebviewContext";

const Sidebar = () => {
  const {
    // user,
    openSidebarPanel,
    closeSidebarPanel,
    sidebarPanelState,
    isSidebarOpened,
    setIsSidebarOpened,
  } = useUser();

  const { feedConfig, feedGeneralContent, resetFeedData, setFeedConfig, setFeedGeneralContent } = useFeed();

  const { isMobileWebview } = useMobileWebview();

  const isConnect =
    feedConfig.source === FeedSourceType.CONNECT_ORGANIZATION ||
    feedConfig.source === FeedSourceType.CONNECT_SINGLE_TOPIC;

  function handleGeneralContentClick(generalContent = true) {
    const newSource = isConnect ? FeedSourceType.CONNECT_ORGANIZATION : FeedSourceType.HOME_ORGANIZATION;
    const newSubject = isConnect ? feedConfig.subject : undefined;
    setFeedConfig({
      ...feedConfig,
      page: 1,
      source: newSource,
      subject: newSubject,
    });
    resetFeedData();
    setFeedGeneralContent(generalContent);
  }

  function handleUserClick() {
    setFeedConfig({
      ...feedConfig,
      page: 1,
      source: FeedSourceType.USER,
      subject: undefined,
    });
    resetFeedData();
    setFeedGeneralContent(true);
  }

  // useEffect(() => {
  // 	const results = user.org_tree.children.filter(org =>
  // 		console.log(org.name)
  // 		// org.name.includes(searchTerm)
  // 	);
  // 	setSearchResults(results);
  // }, [searchTerm]);

  useEffect(() => {
    if (isSidebarOpened) {
      const handleSidebarClick = (e) => {
        // if you click anywhere except on one of the buttons that manipulates within the sidebar, close it
        // this effectively closes the sidebar when you use the sidebar to navigate to a different page, or when you click outside of it
        // yes, I am aware that this is a ridiculously hacky way of doing this... but it works well enough for the time being
        if (
          e.target.classList.contains("fa-minus") ||
          e.target.classList.contains("fa-plus") ||
          e.target.classList.contains("fa-chevron-down") ||
          e.target.classList.contains("open_close-menu_link")
        ) {
          return;
        }
        setIsSidebarOpened(false);
      };
      window.addEventListener("click", handleSidebarClick);
      return () => window.removeEventListener("click", handleSidebarClick);
    }
  }, [isSidebarOpened, setIsSidebarOpened]);

  const isUserFeedSource = feedConfig.source === FeedSourceType.USER;
  const isAllContentFeedSource = !isUserFeedSource && feedGeneralContent;
  const isAssignmentFeedSource = !isUserFeedSource && !feedGeneralContent;

  const getLinkUrl = (url) => `${url}${window.location.search}`;

  const isMobile = window.innerWidth < 769;

  return (
    <div className={`sidebar ${isMobileWebview ? "mobile-webview" : ""}`}>
      <div className="sidebar-container">
        <div className="sidebar-title">
          {!isMobile && sidebarPanelState === "opened" ? (
            <MDBIcon icon="angle-double-left" onClick={closeSidebarPanel} />
          ) : (
            <MDBIcon icon="angle-double-right" onClick={openSidebarPanel} />
          )}
          <span>Manifest</span>
        </div>
        <div className="sidebar-actions">
          <div className={isAllContentFeedSource ? "selected" : ""}>
            {/* <Link onClick={() => handleGeneralContentClick(true)} to={getLinkUrl("/assignment/organization")} title="All Content"> */}
            <Link
              onClick={() => handleGeneralContentClick(true)}
              to={getLinkUrl(
                `/assignment/${isConnect ? `connect-organization/${feedConfig.subject}` : `organization`}`,
              )}
              title="All Content"
            >
              <MDBIcon icon="list-ul" data-gc="full-feed" />
            </Link>
          </div>

          <div className={isAssignmentFeedSource ? "selected" : ""}>
            <Link
              onClick={() => handleGeneralContentClick(false)}
              to={getLinkUrl(
                `/assignment/${isConnect ? `connect-organization/${feedConfig.subject}` : `organization`}`,
              )}
              title="Assignments"
            >
              <MDBIcon far icon="file-alt" data-gc="assignments" />
            </Link>
          </div>

          <div className={isUserFeedSource ? "selected" : ""}>
            <Link onClick={handleUserClick} to={getLinkUrl("/assignment/user")} title="My Assignments">
              <MDBIcon icon="user" />
            </Link>
          </div>
        </div>
        <SidebarMenu />
      </div>
    </div>
  );
};

export default Sidebar;
