import React, { useRef, useEffect, useState, useCallback } from "react";
import { useUser } from "contexts/userContext";
import { useSettings } from "contexts/settingsContext";

const ResizablePanels = ({ children }) => {
  const { sidebarPanelState, setSidebarPanelState, sidebarWidth, setSidebarWidth } = useUser();

  const {
    // feedContainerWidth,
    setFeedContainerWidth,
  } = useSettings();

  const sidebarRef = useRef(null);
  const [isResizing, setIsResizing] = useState(false);

  const startResizing = useCallback((mouseDownEvent) => {
    setIsResizing(true);
  }, []);

  const stopResizing = useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = useCallback(
    (mouseMoveEvent) => {
      if (isResizing) {
        setSidebarWidth(mouseMoveEvent.clientX - sidebarRef.current.getBoundingClientRect().left);
      }
    },
    [isResizing],
  );

  useEffect(() => {
    window.addEventListener("mousemove", resize);
    window.addEventListener("mouseup", stopResizing);
    return () => {
      window.removeEventListener("mousemove", resize);
      window.removeEventListener("mouseup", stopResizing);
    };
  }, [resize, stopResizing]);

  useEffect(() => {
    if (sidebarWidth > 56) {
      setSidebarPanelState("opened");
    } else {
      setSidebarPanelState("closed");
    }
  }, [sidebarWidth]);

  useEffect(() => {
    setFeedContainerWidth(window.innerWidth - sidebarWidth);
  }, [sidebarWidth]);

  return (
    <div className="panels-container">
      <div
        ref={sidebarRef}
        className="panel-sidebar_container"
        style={{ width: sidebarWidth }}
        onMouseDown={(e) => e.preventDefault()}
      >
        <div className={`panel-sidebar_content panel-sidebar_${sidebarPanelState}`}>{children[0]}</div>
        <div className="panel-sidebar_resizer" onMouseDown={startResizing} />
      </div>
      <div className="app-frame">{children[1]}</div>
    </div>
  );
};

export default ResizablePanels;
