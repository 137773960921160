import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Manifest from "./manifest/index";
import AddTopic from "./manifest/addTopic";
import AddAssignment from "./manifest/addAssignment";
import EditTopic from "./manifest/editTopic";
import EditAssignment from "./manifest/editAssignment";

import { MobileWebviewContextProvider } from "contexts/mobileWebviewContext";
import { FeedContextProvider } from "contexts/feedContext";
import { UserContextProvider } from "contexts/userContext";
import { SettingsContextProvider } from "contexts/settingsContext";

const App = () => {
  return (
    <MobileWebviewContextProvider>
      <UserContextProvider>
        <SettingsContextProvider>
          <FeedContextProvider>
            <Router>
              <Switch>
                {/* <Route path="/editassignment/:id/:topicTitle" component={EditAssignment} /> */}
                <Route path="/editassignment/:topicId/:assignmentId/:topicTitle" component={EditAssignment} />
                <Route path="/addassignment/:id/:title" component={AddAssignment} />
                <Route path="/edittopic/:id" component={EditTopic} />
                <Route path="/addtopic" component={AddTopic} />
                <Route path="/" component={Manifest} />
              </Switch>
            </Router>
          </FeedContextProvider>
        </SettingsContextProvider>
      </UserContextProvider>
    </MobileWebviewContextProvider>
  );
};

export default App;
