import React, { useState } from "react";
import axios from "axios";
import { MDBIcon } from "mdbreact";
import { useUser } from "contexts/userContext";

const Transcription = ({ userId, videoId, aId }) => {
  const { user } = useUser();
  const [showTranscription, setShowTranscription] = useState(false);
  const [transcription, setTranscription] = useState(null);
  // const [hideTranscription, setHideTranscription] = useState(false)

  const getTranscription = React.useCallback(async () => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/assignment/transcription/${aId}/${videoId}`;
    const queryParameters = new URLSearchParams();
    queryParameters.set("ownerid", user.id);

    try {
      const response = await axios.get(url, { params: queryParameters });
      setTranscription(response.data.transcription);
      setShowTranscription(!showTranscription);
    } catch (error) {
      console.warn(error);
    }
  }, [aId, showTranscription, user, videoId]);

  function actionHideTranscription() {
    // setHideTranscription(true)
    setShowTranscription(!showTranscription);
    setTranscription("");
  }

  let i = 0;

  return (
    <dl className="transcription" key={i++}>
      <dt>
        Transcription
        <i
          title="Show transcription"
          className={showTranscription ? `fas fa-eye-slash hide` : `fas fa-eye-slash`}
          onClick={() => getTranscription(videoId, userId)}
        ></i>
        <i
          title="Hide Transcription"
          className={showTranscription ? `fas fa-eye` : `fas fa-eye hide`}
          onClick={() => actionHideTranscription()}
        ></i>
        <div className="transcription-format">
          {/*  target="_blank" rel="noopener noreferrer" */}
          <a
            href={`/api/gateway.php?userid=${userId}&mode=28&videoid=${videoId}&type=scc&assignmentid=${aId}`}
            title="Download captions_scc"
          >
            <MDBIcon far icon="file-alt" />
          </a>
          <a
            href={`/api/gateway.php?userid=${userId}&mode=28&videoid=${videoId}&type=srt&assignmentid=${aId}`}
            title="Download captions_srt"
          >
            <MDBIcon far icon="file-alt" />
          </a>
          <a
            href={`/api/gateway.php?userid=${userId}&mode=28&videoid=${videoId}&type=txt&assignmentid=${aId}`}
            title="Download transcription txt"
          >
            <MDBIcon far icon="file-alt" />
          </a>
        </div>
      </dt>
      <dd>{transcription !== null && <p>{transcription}</p>}</dd>
    </dl>
  );
};

export default Transcription;
