import { useEffect, useState } from "react";

export const useLocalState = (stateName, initialState) => {
  const [localState, setLocalState] = useState(() => {
    if (typeof window !== "undefined") {
      const state = window.localStorage.getItem(stateName);

      if (state) {
        return JSON.parse(state);
      }
    }

    return initialState;
  });

  useEffect(() => {
    window.localStorage.setItem(stateName, JSON.stringify(localState));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localState]);

  return [localState, setLocalState];
};
